<div class="flex flex-col h-full w-full justify-start items-start bg-[#FFFFFF]">
  <app-swm-stepper class="w-full"></app-swm-stepper>

  <div class="flex flex-col h-full w-full justify-start items-start gap-6 py-12 overflow-hidden" *ngIf="services">
    <div class="flex flex-row w-full gap-6">
      <div class="flex flex-col w-1/2 pl-6">
        <div class="flex flex-row">
          <p class="text-gray-900 ">Verfügbare Prüfregeln:</p>
        </div>
      </div>

      <div class="flex flex-col w-1/2 pr-6 pl-2">
        <div class="flex flex-row">
          <p class="text-gray-900">Ausgewählte Prüfregeln:</p>
        </div>
      </div>
    </div>

    <div class="flex flex-row w-full gap-6 pl-6">

      <div class="flex flex-row w-1/2 h-fit justify-between pr-9">


        <div class="flex flex-row rounded-lg cursor-pointer">
          <p class="text-secondary text-sm border border-gray-200 rounded-lg py-2 px-3 hover:bg-gray-200"
             [ngClass]="{'bg-gray-100': areAllLSelected()}"
             (click)="addOrRemoveFilteredRulesFromSelectedRules()"
          >Alle</p>
        </div>

        <!--div class="flex flex-row justify-start items-center">
          <ng-container *ngIf="!areAllRulesSelected()">
            <div class="rounded-full bg-primary/10 border-2 border-primary hover:bg-primary/75 ">
              <div
                class="text-xs flex flex-row justify-center items-center text-primary p-2 hover:cursor-pointer hover:text-white text-center"
                (click)="addAllRulesToSelectedRules()">
                Alle Qualitätsprüfungsregeln auswählen
              </div>
            </div>

          </ng-container>
          <ng-container *ngIf="areAllRulesSelected()">
            <div class="rounded-full bg-primary border-2 border-primary hover:bg-primary/50 ">
              <div
                class="text-xs flex flex-row justify-center items-center text-white p-2 hover:cursor-pointer hover:text-primary text-center"
                (click)="removeAllRulesFromSelectedRules()">
                Alle Qualitätsprüfungsregeln abwählen
              </div>
            </div>
          </ng-container>
        </div-->
      </div>
    </div>

    <div class="flex flex-row overflow-hidden h-full w-full gap-4">

      <div class="flex flex-col w-1/2">
        <div *ngIf="areAllLSelected()" class="flex h-full justify-center items-center">Alle Prüfregeln ausgewählt</div>
        <div *ngIf="!areAllLSelected()" class="flex flex-col justify-start items-start h-full w-full overflow-hidden">
          <div class="flex flex-col flex-grow w-full overflow-y-scroll gap-3 py-1 pl-6 pr-4"
               cdkDropList #lAll="cdkDropList"
               [cdkDropListData]="allServices"
               [cdkDropListConnectedTo]="[lSelected]"
               (cdkDropListDropped)="drop($event)">
            <div class="flex flex-row shadow w-full rounded-lg justify-between items-center hover:cursor-grab"
                 cdkDrag *ngFor="let s of allServices">
              <div class="flex flex-row justify-start items-center p-4 gap-4">
                <ng-container *ngIf="!selectedServices.includes(s)">
                  <button (click)="addToSelectedServices(s)">
                    <fa-icon class="text-primary text-lg" [icon]="faPlusCircle"></fa-icon>
                  </button>
                </ng-container>
                <ng-container *ngIf="selectedServices.includes(s)">
                  <button mat-icon-button (click)="removeFromSelectedServices(s)">
                    <fa-icon [icon]="faCircleXmark" class="text-bic-yellow"></fa-icon>
                  </button>
                </ng-container>
                <div class="font-medium text-gray-700">
                  {{ s.description }}
                </div>
              </div>
              <div class="flex flex-row justify-end items-center gap-2 p-2">
                <div class="flex flex-row justify-end items-center gap-2">
                  <div
                    class="bg-[#E6EAEC] p-0.5 px-3 rounded font-medium text-sm text-gray-500">
                    BSK
                  </div>

                </div>

                <!--            <div class="text-lg" [tooltip]="rule.name" [tooltipSubtext]="getRuleInfoTooltip(rule)" [tooltipDirection]="'right'" >-->
                <!--              <fa-icon [icon]="faCircleInfo" class="text-gray-300 text-lg"></fa-icon>-->
                <!--            </div>-->
              </div>
              <!--            <app-bic-tag *ngIf="selectedLph != lphEnum.LPH4" [class_css]=" s.includes('GL') ? 'bg-primary rounded-md text-white font-medium px-4' : 'bg-primary/50 rounded-md text-white font-medium px-4'" >{{s.includes('GL') ? 'GL' : 'BL'}}</app-bic-tag>-->
              <!--            <app-bic-tag *ngIf="selectedLph == lphEnum.LPH4" [class_css]=" s.includes('GL') ? '' : 'bg-primary/50 rounded-md text-white font-medium px-4'" >{{s.includes('GL') ? '' : 'BL'}}</app-bic-tag>-->
            </div>
          </div>
        </div>

      </div>

      <!--div class="w-1 bg-primary rounded-lg">
      </div-->

      <div class="flex flex-col w-1/2">

        <div class="flex flex-col justify-start items-start h-full w-full pr-6">
          <div class="flex flex-col flex-grow w-full py-1 pr-2 h-[inherit]" cdkDropList
               #selectedQualityRulesList="cdkDropList"
               [cdkDropListData]="selectedServices"
               [cdkDropListConnectedTo]="[lAll]"
               (cdkDropListDropped)="drop($event); fileLeave($event)"
               (cdkDropListEntered)="fileOver($event)"
               (cdkDropListExited)="fileLeave($event)">

            <div class=" flex flex-col w-full h-full">
              <div class="flex flex-col w-full overflow-y-scroll">
                <div class="flex flex-col gap-3 pr-2 pl-2" [ngClass]="{'pb-3': selectedServices.length>0}">
                  <div
                    class="flex flex-row shadow w-full rounded-lg justify-between items-center hover:cursor-grab"
                    *ngFor="let s of selectedServices" cdkDrag>
                    <div class="flex flex-row justify-start items-center p-4 gap-4">
                      <button (click)="removeFromSelectedServices(s)">
                        <fa-icon [icon]="faCircleXmark" class="text-bic-yellow text-lg"></fa-icon>
                      </button>
                      <div class="font-medium text-gray-700">
                        {{s.description}}
                      </div>
                    </div>
                    <div class="flex flex-row justify-end items-center gap-2 p-2">
                      <div class="flex flex-row justify-end items-center gap-2">
                        <div
                          class="bg-[#E6EAEC] p-0.5 px-3 rounded font-medium text-sm text-gray-500">
                          BSK
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div class="pr-2 pl-2 flex-grow min-h-[138px]" *ngIf="allServices.length != 0">
                <div
                  class="h-full justify-center items-center bg-gray-50 border-2 border-dashed border-[#C6C9CB] rounded-lg p-4"
                  [class]="dropZoneClassName">
                  <div class="flex flex-col justify-center items-start w-full h-full gap-[7px]" [class]="contentClassName">
                    <div class="flex flex-row justify-center items-center w-full" *ngIf="!entered">
                      <!--mat-icon class="scale-[5]" [svgIcon]="'plus-circle'"></mat-icon-->
                      <fa-icon class="text-gray-400 text-[48px]" [icon]="faSquareDashedCirclePlus"></fa-icon>
                    </div>
                    <div class="flex flex-row justify-center items-center w-full text-gray-400">
                      <text *ngIf="entered">Hier loslassen</text>
                      <text *ngIf="!entered"><b>Prüfregeln</b> per Drag & Drop auswählen</text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="flex flex-row w-full justify-center items-center">
      <bic-button (click)="startCheck()" [disabled]="selectedServices.length == 0" [text]="'Überprüfen'" [tooltip]="'Überprüfung starten'">
      </bic-button>
    </div>
  </div>
</div>
