<div class="h-full w-full overflow-y-scroll p-6">
  <mat-accordion>
    <mat-expansion-panel *ngIf="organisationForm" class="p-6" expanded #orgaPanel>
      <mat-expansion-panel-header>
        <mat-panel-title>
        <div class="flex flex-row items-center gap-2.5" [class]="orgaPanel.expanded ? 'text-primary' : 'text-secondary'">
          <div class="w-8 h-8 rounded-[50px] border justify-center items-center gap-2.5 inline-flex" [class]="orgaPanel.expanded ? 'border-primary' : 'border-secondary'">
            <div class="text-[#0c0f0a] text-sm font-medium font-['Inter'] leading-[17.50px]" [class]="orgaPanel.expanded ? 'text-primary' : 'text-secondary'">1</div>
            </div>
            <span class="text-xl">Neue Organisation</span>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="generalData"></ng-container>
    </mat-expansion-panel>
    <mat-expansion-panel #addressPanel class="p-6">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="flex flex-row items-center gap-2.5" [class]="addressPanel.expanded ? 'text-primary' : 'text-secondary'">
            <div class="w-8 h-8 rounded-[50px] border justify-center items-center gap-2.5 inline-flex" [class]="addressPanel.expanded ? 'border-primary' : 'border-secondary'">
              <div class="text-[#0c0f0a] text-sm font-medium font-['Inter'] leading-[17.50px]" [class]="addressPanel.expanded ? 'text-primary' : 'text-secondary'">2</div>
            </div>
            <span class="text-xl">Adresse</span>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngIf="organisationForm">
        <ng-container  *ngTemplateOutlet="placeData"></ng-container>
      </ng-container>
    </mat-expansion-panel>
<!--    <mat-expansion-panel *ngIf="organisationForm" #memberPanel class="p-6">-->
<!--      <mat-expansion-panel-header>-->
<!--        <mat-panel-title>-->
<!--          <div class="flex flex-row items-center gap-2.5 text-secondary">-->
<!--            <div class="w-8 h-8 rounded-[50px] border border-[#0c0f0a] justify-center items-center gap-2.5 inline-flex">-->
<!--              <div class="text-[#0c0f0a] text-sm font-medium font-['Inter'] leading-[17.50px]">3</div>-->
<!--            </div>-->
<!--            <span class="text-xl">Mitglieder</span>-->
<!--          </div>-->
<!--        </mat-panel-title>-->
<!--      </mat-expansion-panel-header>-->
<!--      <ng-container *ngTemplateOutlet="memberData"></ng-container>-->
<!--    </mat-expansion-panel>-->
  </mat-accordion>

  <!--  <mat-stepper orientation="vertical" [linear]="true">-->
  <!--    <mat-step label="Personenbezogene Daten" #stepRegister>-->
  <!--      <ng-container *ngTemplateOutlet="registerData"></ng-container>-->
  <!--      <div class="flex flex-row justify-end items-center gap-2">-->
  <!--        <button color="primary" mat-raised-button matStepperNext [disabled]="!registerForm.valid" (click)="stepRegister.completed = true; completeRegistration()">Registrierung abschließen</button>-->
  <!--      </div>-->
  <!--    </mat-step>-->

  <!--  </mat-stepper>-->
</div>






<ng-template #generalData>
  <form [formGroup]="organisationForm" class="column-form flex flex-col justify-between">
    <div class="flex flex-row items-start justify-between">
      <div class="flex flex-col w-full gap-6 py-12">
        <div class="flex flex-row gap-6">
          <bic-input class="w-full" [label]="'Name der Organisation *'" [formControlName]="'name'" [placeholder]="'Eingeben'"
                     [required]="true"></bic-input>

          <bic-select class="w-full" [label]="'Rechtsform'" [formControlName]="'legalForm'" [placeholder]="'Auswählen'"
                      [required]="true">
            <bic-dropdown-element *ngFor="let i of legalForm | keyvalue" [value]="i.key">
              {{ i.value | legalForms }}
            </bic-dropdown-element>
          </bic-select>

<!--          <mat-form-field appearance="outline" class="flex w-1/2">-->
<!--            <mat-label>Rechtsform</mat-label>-->
<!--            <mat-select formControlName="legalForm" [disabled]="organisationForm.controls['legalForm'].disabled">-->
<!--              <mat-option *ngFor="let i of legalForm | keyvalue"-->
<!--                          [value]="i.key">{{ i.value | legalForms }}-->
<!--              </mat-option>-->
<!--            </mat-select>-->
<!--          </mat-form-field>-->
        </div>
        <div class="flex flex-row gap-6">
          <bic-input class="w-full" [label]="'Geschäftliche E-Mail *'" [formControlName]="'organisationMail'" [placeholder]="'Eingeben'"></bic-input>

<!--          <mat-form-field appearance="outline" class="flex w-1/2">-->
<!--            <mat-label>Geschäftliche E-Mail</mat-label>-->
<!--            <input formControlName="organisationMail" matInput>-->
<!--          </mat-form-field>-->

          <bic-input class="w-full" [label]="'Umsatzsteueridentifikationsnummer'" [formControlName]="'vatNumber'" [placeholder]="'Eingeben'"></bic-input>

<!--          <mat-form-field appearance="outline" class="flex w-1/2">-->
<!--            <mat-label>Umsatzsteueridentifikationsnummer</mat-label>-->
<!--            <input formControlName="vatNumber" matInput>-->
<!--          </mat-form-field>-->
        </div>
      </div>
    </div>


  </form>
  <div class="flex flex-row justify-between">
    <bic-button [text]="'Abbrechen'" (onClick)="cancel()" [textColor]="'gray-900'" [color]="'white'" [border]="true"></bic-button>
    <bic-button [text]="'Weiter'" (onClick)="addressPanel.open()" ></bic-button>

  </div>
</ng-template>

<ng-template #placeData>
  <app-address-input [formGroup]="organisationForm"></app-address-input>
  <div class="flex flex-row justify-between pt-12">
    <bic-button [text]="'Abbrechen'" (onClick)="cancel()" [textColor]="'gray-900'" [color]="'white'" [border]="true"></bic-button>
    <bic-button [text]="'Erstellen'" (onClick)="save()" ></bic-button>

  </div>
</ng-template>

<ng-template #memberData>
  <ng-container
    *ngIf="organisation && permissionService.hasOrganisationPrivilege('ORGANISATION_USER_MANAGEMENT', organisation.id!)">

    <div class="flex flex-col justify-start gap-8">
      <app-users-list
        *ngIf="organisation.id != -1 && permissionService.hasOrganisationPrivilege('ORGANISATION_USER_MANAGEMENT', organisation.id!)"
        [addDisabled]="!permissionService.hasOrganisationPrivilege('ORGANISATION_USER_MANAGEMENT', organisation.id!)"
        [deleteDisabled]="!permissionService.hasOrganisationPrivilege('ORGANISATION_USER_MANAGEMENT', organisation.id!)"
        [permissionsDisabled]="!permissionService.hasOrganisationPrivilege('ORGANISATION_USER_MANAGEMENT', organisation.id!)"
        [isProject]="false"
        [organisation]="organisation!"
        [changeEventTriggered]="organisationChangedEvent.asObservable()"
      ></app-users-list>
    </div>



    </ng-container>
</ng-template>

