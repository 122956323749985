import {Component, OnInit} from '@angular/core';
import {CircleState} from "../../util/design/circle-state/circle-state.component";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClipboardList
} from "@fortawesome/free-solid-svg-icons";
import {QualityCheck, QualityControllerService, Task} from "../../../api";
import {TaskDetailsService} from "../../../services/task-details.service";
import {QualityService, Rule} from "../../../quality-api";
import {ActivatedRoute, Router} from "@angular/router";
import {ExchangeService} from "../../../services/exchange/exchange.service";
import {StateConfigService} from "../../../services/state/state-config.service";

@Component({
  selector: 'app-quality-result-report',
  templateUrl: './quality-result-report.component.html',
  styleUrls: ['./quality-result-report.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class QualityResultReportComponent implements OnInit {

  qualityCheck!: QualityCheck
  rule!: Rule

  fileId: string = '';

  displayedColumnsNames = ['Text', 'Typ', 'Anzahl Bauteile']
  displayedColumns = ['responseText', 'type', 'count']
  columnsWidths = ['70%', '20%', '10%']
  columnsTypes = ['text', 'status', 'text']
  columnsAlignment = ['start', 'center', 'center']

  subDisplayedColumnsNames: string[] = []
  subDisplayedColumns: string[] = []
  subColumnsWidths: string[] = []
  subColumnsTypes: string[] = []
  subColumnsAlignment: string[] = []

  data: any[] = []
  subData: any[] = []

  expandedElement!: any;


  constructor(
    private taskDetailService: TaskDetailsService,
    private qualityService: QualityService,
    private qualityControllerService: QualityControllerService,
    private activatedRoute: ActivatedRoute,
    private exchangeService: ExchangeService,
    private router: Router,
    private stateConfigService: StateConfigService
  ) {
  }

  ngOnInit() {
    this.qualityCheck = this.activatedRoute.parent?.parent?.snapshot.data.qualityCheck;
    this.fileId = this.activatedRoute.snapshot.params.fileId;
    const checkId = this.qualityCheck.checkIdsOfProjectFiles![this.fileId];
    const ruleId = this.activatedRoute.snapshot.params.ruleId;

    this.qualityControllerService.getRules1().subscribe(rules => {
      this.rule = rules.find(rule => rule.id === ruleId) as Rule;

      this.rule.tableColumns?.forEach(column => {
        if (column.name != 'space_usage' && column.name != 'shading_device') {
          this.subDisplayedColumns.push(column.name!)
          this.subDisplayedColumnsNames.push(column.description!)
        }
      });
      if (this.subDisplayedColumnsNames.length > 0) {
        this.subDisplayedColumns.unshift('title')
        this.subDisplayedColumnsNames.unshift('Titel')
        this.subDisplayedColumns.unshift('id')
        this.subDisplayedColumnsNames.unshift('Id')
      }
      this.subColumnsTypes = new Array(this.subDisplayedColumnsNames.length).fill('text')
      let width = this.calculateColumnWidth(this.subDisplayedColumnsNames.length)
      this.subColumnsWidths = new Array(this.subDisplayedColumnsNames.length).fill(width + '%')
      this.subColumnsAlignment = new Array(this.subDisplayedColumnsNames.length).fill('center')


      console.log(this.subDisplayedColumns)
      this.data = this.qualityCheck.qualityCheckProjectFiles!.find(file => file.projectFile!.id === this.fileId)!.check!.result[this.rule.codeId!]!
    });
  }

  calculateColumnWidth(x: number): string {
    const maxTotalWidth = 100.00;
    const step = 0.05;
    let widthPerItem = maxTotalWidth / x;

    // Round to the nearest 0.05
    widthPerItem = Math.round(widthPerItem / step) * step;

    // Ensure the total width does not exceed 100%
    if (widthPerItem * x > maxTotalWidth) {
      widthPerItem = Math.floor((maxTotalWidth / x) / step) * step;
    }

    let shifted = widthPerItem * 10;
    // Floor to get the largest integer less than or equal to the number
    let floored = Math.floor(shifted);
    // Adjust to ensure the second decimal is 0 or 5
    floored = Math.floor(floored / 5) * 5;
    // Divide by 10 to shift the decimal point back
    let result = floored / 10;
    // Return as a string with 2 decimal places
    return result.toFixed(2);
  }

  createTask(element: any) {
    let task = {
      markup: {
        topic: {
          title: 'Überarbeiten notwendig: ' + element.responseText,
        }
      },
    }

    this.taskDetailService.setOpenedTask(task as Task);

  }

  get completed() {
    return (100 / this.total) * this.data.filter(result => result.type === 'success').length
  }

  get warning() {
    return (100 / this.total) * this.data.filter(result => result.type === 'warning').length
  }

  get error() {
    return (100 / this.total) * this.data.filter(result => result.type === 'error').length
  }

  get total() {
    return this.data.length
  }

  setGuids() {
    console.log(this.expandedElement)
    if (this.expandedElement == null) {
      this.exchangeService.clear();
    } else {
      let guids = this.expandedElement.components.flatMap((component: { id: any; }) => component.id);
      if (this.expandedElement.type === 'success') {
        this.exchangeService.setSuccessGuids(guids);
      } else if (this.expandedElement.type === 'warning') {
        this.exchangeService.setWarningGuids(guids);
      } else if (this.expandedElement.type === 'error') {
        this.exchangeService.setFailedGuids(guids);
      }
    }
  }


  changeSubData(row: any) {
    let array: any[] = []
    row.components.forEach((obj2: any) => {
      array.push({id: obj2.id, title: obj2.title, ...obj2.attributes})
    })
    this.subData = array
  }

  navigateBack() {
    this.router.navigate(["projects", this.stateConfigService.getProjectId(), "quality", this.qualityCheck.id, "report", this.fileId]);
  }

  protected readonly CircleState = CircleState;
  protected readonly faChevronUp = faChevronUp;
  protected readonly faChevronDown = faChevronDown;
  protected readonly undefined = undefined;
  protected readonly faClipboardList = faClipboardList;
  protected readonly faChevronLeft = faChevronLeft;
  protected readonly faChevronRight = faChevronRight;
}
