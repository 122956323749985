import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Parameter} from "@angular/compiler-cli/src/ngtsc/reflection";
import {StateConfigService} from "../../../services/state/state-config.service";

@Component({
  selector: 'app-quality-check-confirmation',
  templateUrl: './quality-check-confirmation.component.html',
  styleUrls: ['./quality-check-confirmation.component.scss']
})
export class QualityCheckConfirmationComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {}, private dialogRef: MatDialogRef<QualityCheckConfirmationComponent>,
  public stateConfigService: StateConfigService) { }

  values = {};

  ngOnInit(): void {
  }

}
