import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
  AuthControllerService,
  Organisation,
  OrganisationControllerService,
  Project,
  ProjectControllerService, Role,
  User,
  UserControllerService,
  UserFullDto
} from "../../../api";
import {MatSort} from "@angular/material/sort";
import {ActivatedRoute} from "@angular/router";
import {AddUserDialogComponent} from "./add-user-dialog/add-user-dialog.component";
import {ToolbarService} from "../design/toolbar/toolbar.service";
import {Observable, Subscription} from "rxjs";
import {PermissionService} from "../../../services/permission/permission.service";
import {StateConfigService} from "../../../services/state/state-config.service";
import {
  faCaretDown,
  faChevronRight,
  faClipboardList,
  faEllipsisVertical,
  faLock,
  faPenToSquare,
  faPlus,
  faTrashCan,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatTableDataSource} from "@angular/material/table";
import {CustomRoleDetailComponent} from "../custom-role-detail/custom-role-detail.component";
import {CircleState} from "../design/circle-state/circle-state.component";


@Component({
  selector: 'app-users-list[isProject]',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersListComponent implements OnInit {

  @Input() fromProjectAccordion: boolean = false;

  @Input() isProject!: boolean;

  @Input() addDisabled: boolean = false;
  @Input() deleteDisabled: boolean = false;
  @Input() permissionsDisabled: boolean = false;

  private eventsSubscription!: Subscription;
  @Input() changeEventTriggered!: Observable<Organisation>;

  @Output() usersChange: EventEmitter<User[]> = new EventEmitter<User[]>();

  user!: User;
  @Input() organisation!: Organisation;
  @Input() project!: Project;

  users: User[] = [];

  displayedColumnsProject: string[] = ['firstName', 'lastName', 'mail', 'status', 'authorization', 'actions'];

  displayedColumnsOrganisation: string[] = ['firstName', 'lastName', 'mail', 'status', 'authorization', 'actions'];

  dataSource!: MatTableDataSource<User>;

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('usersTbSort') usersTbSort = new MatSort();

  mailSendMap: Map<number, boolean> = new Map<number, boolean>()

  protected readonly faUserPlus = faUserPlus;
  protected readonly faChevronRight = faChevronRight;
  protected readonly faCaretDown = faCaretDown;
  protected readonly faTrashCan = faTrashCan;
  protected readonly faPlus = faPlus;
  protected readonly faLock = faLock;
  protected readonly faEllipsisVertical = faEllipsisVertical;
  protected readonly faPenToSquare = faPenToSquare;

  authToDropdownFrom: User | undefined = undefined;
  clickedInDropdown = false;
  showRoleInfoFor: Role | undefined;
  selectedCustomRole: Role | undefined = undefined;
  triggerOrigin: HTMLElement | undefined;

  constructor(private route: ActivatedRoute, private dialog: MatDialog,
              public userControllerService: UserControllerService,
              public projectControllerService: ProjectControllerService,
              public snackBar: MatSnackBar,
              public organisationControllerService: OrganisationControllerService,
              private toolbar: ToolbarService, protected permissionService: PermissionService,
              private stateConfigService: StateConfigService,
              private authControllerService: AuthControllerService,
  ) {


    // this.toolbar.config = {
    //   add: {
    //     disabled: () => this.addDisabled,
    //     action: () => this.addUser()
    //   }
    // }

  }

  get canAddUser(): boolean {
    if (this.isProject) {
      return this.stateConfigService.canAddProjectMember();
    }
    return true
  }

  toggleOrigin(origin: HTMLElement, role: Role) {
    this.selectedCustomRole = role;
    this.triggerOrigin = origin;
  }

  ngOnInit(): void {

    this.route.data.subscribe(data => {
      this.user = data.user;

      if (!this.user) {
        this.authControllerService.profile().subscribe(user => {
          this.user = user;
        });
      }


      if (!this.project) {
        this.project = data.project;
      }
      if (!this.organisation) {
        this.organisation = data.organisation;
      }

      if (this.isProject) {
        this.projectControllerService.getUsers(this.project.id!).subscribe(users => {
          this.users = users;
          this.dataSource = new MatTableDataSource(this.users);
          for (let user of this.users) {
            if (user.isActivated == null || !user.isActivated) {
              this.mailSendMap.set(user.id!, false);
            } else {
              this.mailSendMap.set(user.id!, true);
            }
          }
        });
      } else if (this.organisation && this.organisation.id) {
        this.organisationControllerService.getOrganisationUsers(this.organisation.id!).subscribe(users => {
          this.users = users;
          this.dataSource = new MatTableDataSource(this.users);
          for (let user of this.users) {
            if (user.isActivated == null || !user.isActivated) {
              this.mailSendMap.set(user.id!, false);
            } else {
              this.mailSendMap.set(user.id!, true);
            }
          }
        });
      }
    });

    if (this.changeEventTriggered) {
      this.eventsSubscription = this.changeEventTriggered.subscribe((organisation) => {
        this.organisation = organisation;
        this.reloadUsers();
      });
    }
  }

  ngAfterViewInit() {
    if (this.isProject) {
      this.projectControllerService.getUsers(this.project.id!).subscribe(users => {
        this.users = users;
        this.dataSource = new MatTableDataSource(this.users);
        this.dataSource.sortingDataAccessor = (row: User, columnName: string): string => {
          if (columnName === 'role') {
            return row.roleInProjects![this.project?.id!].name ?? '';
          } else if (columnName === 'firstname') {
            return row.firstName || '';
          } else if (columnName === 'lastname') {
            return row.lastName || '';
          } else if (columnName === 'status') {
            if (row.isActivated === null) {
              return 'a';
            }
            return row.isActivated ? 'Registrierung ausstehend' : 'Erfolgreich hinzugefügt';
          }
          return row[columnName as keyof User] as string;
        };

        this.dataSource.sort = this.usersTbSort;
      });

    } else if (this.organisation && this.organisation.id) {
      this.organisationControllerService.getOrganisationUsers(this.organisation.id!).subscribe(users => {
        this.users = users;
        this.dataSource = new MatTableDataSource(this.users);
        this.dataSource.sortingDataAccessor = (row: User, columnName: string): string => {
          if (columnName === 'role') {
            return row.roleInOrganisations![this.organisation?.id!].name ?? '';
          } else if (columnName === 'firstname') {
            return row.firstName ?? '';
          } else if (columnName === 'lastname') {
            return row.lastName ?? '';
          } else if (columnName === 'status') {
            if (row.isActivated === null) {
              return 'a';
            }
            return row.isActivated ? 'Registrierung ausstehend' : 'Erfolgreich hinzugefügt';
          }
          return row[columnName as keyof User] as string;
        };

        this.dataSource.sort = this.usersTbSort;

      });

    }

  }

  addUser(): void {
    let ref = this.dialog.open(AddUserDialogComponent, {
      panelClass: "rounded-corners-dialog",
      data: {
        isProject: this.isProject,
        project: this.project,
        organisation: this.organisation
      },
      minWidth: '25%'
    })

    ref.afterClosed().subscribe(user => {
      this.usersChange.emit(this.users);
      this.ngAfterViewInit()
    });
  }

  removeUser(user: User): void {
    // if (this.project.projectManager?.id == user.id) {
    //   this.snackBar.open("Sie können nicht den Projektverantwortlichen löschen. Ernennen Sie zuerst jemand anderen.", "OK", {duration: 5000});
    // } else {
    this.users = this.users?.filter(p => p.id != user.id)

    if (this.isProject) {
      this.projectControllerService.removeUser(this.stateConfigService.getProjectId()!, user.id!).subscribe(() => {
        this.usersChange.emit(this.users);
        this.snackBar.open("Projektmitglied wurde erfolgreich entfernt", "OK", {
          duration: 3000,
        });
        this.ngAfterViewInit()
      });

    } else {
      this.organisationControllerService.deleteOrganisationUser(this.organisation.id!, user.id!).subscribe(() => {
        this.usersChange.emit(this.users);
        this.snackBar.open("Organisationsmitglied wurde erfolgreich entfernt", "OK", {
          duration: 3000,
        });
        this.ngAfterViewInit()
      });
    }
    // }
  }

  updateUser(user: User, role: Role): void {
    if (this.project) {
      this.projectControllerService.updateUserPermissions(role, this.project.id!, user.id!).subscribe(() => {
        this.snackBar.open("Berechtigung wurde erfolgreich geändert", "OK", {
          duration:3000,
        });
        this.ngAfterViewInit()
      });

    } else {
      this.organisationControllerService.updateOrganisationUser(role, this.organisation.id!, user.id!).subscribe(() => {
        this.snackBar.open("Rolle wurde erfolgreich geändert", "OK", {
          duration:3000,
        });
        this.ngAfterViewInit()
      });
    }

  }

  reloadUsers(): void {
    if (this.organisation && !this.permissionsDisabled) {
      this.organisationControllerService.getOrganisationUsers(this.organisation.id!).subscribe(users => {
        this.users = users;
        this.dataSource = new MatTableDataSource(this.users);
        this.dataSource.sort = this.usersTbSort;

        this.dataSource.sortingDataAccessor = (row: User, columnName: string): string => {
          if (columnName == "role") {
            // @ts-ignore
            return row.projectPermissions[this.project?.id!].role?.toString() ?? "";
          } else if (columnName == "firstname") {
            return row.firstName ?? "";
          } else if (columnName == "lastname") {
            return row.lastName ?? "";
          } else if (columnName == "status") {
            if (row.isActivated == null) {
              return "a";
            }
            return row.isActivated ?? true ? "Registrierung ausstehend" : "Erfolgreich hinzugefügt";
          }
          return row[columnName as keyof User] as string;
        }
      });
    }
    //TODO reload users for project
  }

  get roles(): Role[] {
    if (this.project) {
      return this.project.roles ?? [];
    } else {
      return this.organisation.roles ?? [];
    }

  }

  get customRoles(): Role[] {
    if (this.project) {
      return this.project.customRoles ?? [];
    } else {
      return this.organisation.customRoles ?? [];
    }
  }

  resendInvitation(user: User): void {
    if (this.isProject) {
      this.projectControllerService.inviteToProject([user.mail ?? ""], this.project.id!).subscribe(() => {
        this.snackBar.open("Einladung erfolgreich erneut versendet", "OK", {duration: 5000});
      });
    } else {
      this.organisationControllerService.inviteToOrganisation(this.organisation.id!, [user.mail ?? ""]).subscribe(() => {
        this.snackBar.open("Einladung erfolgreich erneut versendet", "OK", {duration: 5000});
      });
    }
  }

  createCustomRole( user:  User) {
    let customRoleDialog = this.dialog.open(CustomRoleDetailComponent, {
      panelClass: "rounded-corners-dialog",
      data: {
        roleName: "",
        isProject: this.isProject,
        organisation: this.organisation
      },
      width: "30%"
    }).afterClosed().subscribe(result  => {
      if(result){
        this.updateUser(user, result)
      }
      if (this.isProject) {
        this.projectControllerService.getProject(this.project.id!).subscribe(project => {
          this.project = project;
        });
      } else {
        this.organisationControllerService.getOrganisation(this.organisation.id!).subscribe(organisation => {
          this.organisation = organisation;
        });
      }
    })
  }

  //might need to do different things
  editCustomRole(role: Role) {
    let customRoleDialog = this.dialog.open(CustomRoleDetailComponent, {
      panelClass: "rounded-corners-dialog",
      data: {
        role: role,
        isProject: this.isProject,
        organisation: this.organisation
      },
      width: "30%"
    }).afterClosed().subscribe(() => {
      if (this.isProject) {
        this.projectControllerService.getProject(this.project.id!).subscribe(project => {
          this.project = project;
        });
      } else {
        this.organisationControllerService.getOrganisation(this.organisation.id!).subscribe(organisation => {
          this.organisation = organisation;
        });
      }
    })
  }

  deleteCustomRole(role: Role) {
    if (this.isProject) {
      this.projectControllerService.deleteRole(this.project.id!, role.id!).subscribe(() => {
        this.projectControllerService.getProject(this.project.id!).subscribe(project => {
          this.project = project;
        });
      });
    } else {
      this.organisationControllerService.deleteRole1(this.organisation.id!, role.id!).subscribe(() => {
        this.organisationControllerService.getOrganisation(this.organisation.id!).subscribe(organisation => {
          this.organisation = organisation;
        });
      });
    }

  }

  openedSubMenu() {
    setTimeout(() => {
      this.clickedInDropdown = false;
    }, 100)
  }

  onClickSomewhereToCloseMenues() {
    if (!this.clickedInDropdown) {
      this.authToDropdownFrom = undefined;
      this.selectedCustomRole = undefined
    }
  }

  permissionToAddUser(element: User): void {
    if(!this.permissionsDisabled && element.id != this.user.id){
      this.authToDropdownFrom = element
    }else{
      this.authToDropdownFrom = undefined;
    }

  }

  protected readonly faClipboardList = faClipboardList;

  protected readonly CircleState = CircleState;
}
