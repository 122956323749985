<div *ngIf="isInit" class="h-full w-full overflow-y-scroll p-6">
  <mat-accordion>
    <mat-expansion-panel *ngIf="registerForm" #registerPanel class="p-6" expanded >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="flex flex-row items-center gap-2.5" [class]="registerPanel.expanded ? 'text-primary' : 'text-secondary'">
            <div class="w-8 h-8 rounded-[50px] border justify-center items-center gap-2.5 inline-flex" [class]="registerPanel.expanded ? 'border-primary' : 'border-secondary'">
              <div class="text-[#0c0f0a] text-sm font-medium font-['Inter'] leading-[17.50px]" [class]="registerPanel.expanded ? 'text-primary' : 'text-secondary'">1</div>
            </div>
           <span class="text-xl">Profil</span>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="registerData"></ng-container>
    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="addressInfoForm" #addressPanel class="p-6">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="flex flex-row items-center gap-2.5" [class]="addressPanel.expanded ? 'text-primary' : 'text-secondary'">
            <div class="w-8 h-8 rounded-[50px] border justify-center items-center gap-2.5 inline-flex" [class]="addressPanel.expanded ? 'border-primary' : 'border-secondary'">
              <div class="text-[#0c0f0a] text-sm font-medium font-['Inter'] leading-[17.50px]" [class]="addressPanel.expanded ? 'text-primary' : 'text-secondary'">2</div>
            </div>
            <span class="text-xl">Adresse</span>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="addressInfo"></ng-container>
    </mat-expansion-panel>
    <mat-expansion-panel #twoFaPanel class="p-6">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="flex flex-row items-center gap-2.5" [class]="twoFaPanel.expanded ? 'text-primary' : 'text-secondary'">
            <div class="w-8 h-8 rounded-[50px] border justify-center items-center gap-2.5 inline-flex" [class]="twoFaPanel.expanded ? 'border-primary' : 'border-secondary'">
              <div class="text-[#0c0f0a] text-sm font-medium font-['Inter'] leading-[17.50px]" [class]="twoFaPanel.expanded ? 'text-primary' : 'text-secondary'">3</div>
            </div>
            <span class="text-xl">Kontosicherheit (Empfohlen)</span>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="twoFaData"></ng-container>
    </mat-expansion-panel>
  </mat-accordion>

<!--  <mat-stepper orientation="vertical" [linear]="true">-->
<!--    <mat-step label="Personenbezogene Daten" #stepRegister>-->
<!--      <ng-container *ngTemplateOutlet="registerData"></ng-container>-->
<!--      <div class="flex flex-row justify-end items-center gap-2">-->
<!--        <button color="primary" mat-raised-button matStepperNext [disabled]="!registerForm.valid" (click)="stepRegister.completed = true; completeRegistration()">Registrierung abschließen</button>-->
<!--      </div>-->
<!--    </mat-step>-->

<!--  </mat-stepper>-->
</div>

<div *ngIf="user && !isInit" class="mt-2 h-full w-full overflow-y-scroll px-4 pb-2 pt-8">
  <mat-accordion>
    <mat-expansion-panel *ngIf="registerForm" class="py-2 px-4" expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="text-lg font-regular text-secondary">Profil</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="registerData"></ng-container>
    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="true" class="py-2 px-4">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="text-lg font-regular text-secondary">Adresse</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="addressInfo"></ng-container>
    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="true" class="py-2 px-4">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="text-lg font-regular text-secondary">Organisationen</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-organisation [user]="user"></app-organisation>
    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="true" class="py-2 px-4">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="text-lg font-regular text-secondary">Projekte</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-user-projects [user]="user"></app-user-projects>
    </mat-expansion-panel>
    <mat-expansion-panel class="py-2 px-4">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="text-lg font-regular text-secondary">Konto und Sicherheit</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="twoFaData"></ng-container>
      <div class="flex flex-row w-full border border-gray-100 my-6"></div>
      <ng-container *ngTemplateOutlet="changePasswordData"></ng-container>
    </mat-expansion-panel>

    <!--    <mat-expansion-panel class="py-2 px-4">-->
    <!--      <mat-expansion-panel-header>-->
    <!--        <mat-panel-title>-->
    <!--          <div class="text-lg font-regular text-secondary">2-Faktor-Authentifizierung</div>-->
    <!--        </mat-panel-title>-->
    <!--      </mat-expansion-panel-header>-->

    <!--      <ng-container>-->

    <!--      </ng-container>-->

    <!--    </mat-expansion-panel>-->

  </mat-accordion>
</div>

<ng-template #registerData>
  <form [formGroup]="registerForm">
    <div class="flex flex-col gap-16">
      <div class="flex flex-row justify-between pt-2 gap-8">
        <app-profile-image-upload [(image)]="user.profileImage"></app-profile-image-upload>
        <div class="flex flex-col justify-between w-full gap-8">
          <div class="flex flex-row gap-8">

            <bic-select class="w-1/4" formControlName="salutation" [label]="'Anrede *'" [required]="true">
              <bic-dropdown-element [value]="salutation.HERR">Herr</bic-dropdown-element>
              <bic-dropdown-element [value]="salutation.FRAU">Frau</bic-dropdown-element>
              <bic-dropdown-element [value]="salutation.NONE">Keine</bic-dropdown-element>
            </bic-select>



<!--            <mat-form-field appearance="outline" class="flex w-1/4 h-12" subscriptSizing="dynamic">-->
<!--              <mat-label>Anrede</mat-label>-->
<!--              <mat-select formControlName="salutation">-->
<!--                <mat-option [value]="salutation.HERR" >{{SalutationEnum.HERR | salutation}}</mat-option>-->
<!--                <mat-option [value]="salutation.FRAU" >{{SalutationEnum.FRAU | salutation}}</mat-option>-->
<!--                <mat-option [value]="salutation.NONE" >{{SalutationEnum.NONE | salutation}}</mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->

            <bic-select class="w-1/4" formControlName="title" [label]="'Titel'" [required]="false">
              <bic-dropdown-element [value]="title.NONE">Kein Titel</bic-dropdown-element>
              <bic-dropdown-element [value]="title.DR">Dr.</bic-dropdown-element>
              <bic-dropdown-element [value]="title.PROF">Prof.</bic-dropdown-element>
              <bic-dropdown-element [value]="title.PROFDR">Prof. Dr.</bic-dropdown-element>
            </bic-select>


<!--            <mat-form-field appearance="outline" class="flex w-1/4 h-12" subscriptSizing="dynamic">-->
<!--              <mat-label>Titel</mat-label>-->
<!--              <mat-select formControlName="title">-->
<!--                <mat-option [value]="title.NONE" >{{TitleEnum.NONE | title}}</mat-option>-->
<!--                <mat-option [value]="title.DR" >{{TitleEnum.DR | title}}</mat-option>-->
<!--                <mat-option [value]="title.PROF" >{{TitleEnum.PROF | title}}</mat-option>-->
<!--                <mat-option [value]="title.PROFDR" >{{TitleEnum.PROFDR | title}}</mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->


            <bic-input class="w-1/4" formControlName="firstName" [label]="'Vorname *'" [required]="true" [errorCondition]="registerForm.controls.firstName.touched && registerForm.controls.firstName.invalid"></bic-input>



<!--            <mat-form-field appearance="outline" class="flex w-1/4 h-12" subscriptSizing="dynamic">-->
<!--              <mat-label>Vorname</mat-label>-->
<!--              <input formControlName="firstName" matInput required onkeydown="if (event.key === 'Enter') this.blur()">-->
<!--              <mat-error *ngIf="registerForm.controls.firstName.touched && registerForm.controls.firstName.invalid">-->
<!--              </mat-error>-->
<!--            </mat-form-field>-->

            <bic-input class="w-1/4" formControlName="lastName" [label]="'Nachname *'" [required]="true" [errorCondition]="registerForm.controls.lastName.touched && registerForm.controls.lastName.invalid"></bic-input>

<!--            <mat-form-field appearance="outline" class="flex w-1/4 h-12" subscriptSizing="dynamic">-->
<!--              <mat-label>Nachname</mat-label>-->
<!--              <input formControlName="lastName" matInput required onkeydown="if (event.key === 'Enter') this.blur()">-->
<!--              <mat-error *ngIf="registerForm.controls.lastName.touched && registerForm.controls.lastName.invalid">-->
<!--              </mat-error>-->
<!--            </mat-form-field>-->
          </div>

          <div class="flex flex-row gap-8" >
            <bic-input class="w-1/4" formControlName="mail" [label]="'Mail'" [disabled]="true"></bic-input>

<!--            <mat-form-field appearance="outline" class="flex w-1/4 h-12" subscriptSizing="dynamic">-->
<!--              <mat-label>Mail</mat-label>-->
<!--              <input disabled formControlName="mail" matInput>-->
<!--            </mat-form-field>-->

            <bic-input class="w-1/4" formControlName="phoneNumber" [label]="'Telefon'" [required]="false" [errorCondition]="registerForm.controls.phoneNumber.touched && registerForm.controls.phoneNumber.invalid"></bic-input>

<!--            <mat-form-field appearance="outline" class="flex w-1/4 h-12" subscriptSizing="dynamic">-->
<!--              <mat-label>Telefon</mat-label>-->
<!--              <input formControlName="phoneNumber" matInput>-->
<!--            </mat-form-field>-->

            <bic-input bicDatepickerInput [minDateToday]="false" class="w-1/4" formControlName="birthdate" [label]="'Geburtstag (DD.MM.YYYY)'" [required]="false" [isDatepicker]="true"
                       (dateChange)="registerForm.get('birthdate')?.setValue($event)"></bic-input>

<!--            <mat-form-field appearance="outline" class="flex w-1/4 h-12" subscriptSizing="dynamic">-->
<!--              <mat-label>Geburtstag (DD.MM.YYYY)</mat-label>-->
<!--              <input [matDatepicker]="birthdayPicker" formControlName="birthdate" matInput [max]="maxDate">-->
<!--              <mat-datepicker-toggle [for]="birthdayPicker" matSuffix></mat-datepicker-toggle>-->
<!--              <mat-datepicker #birthdayPicker></mat-datepicker>-->
<!--            </mat-form-field>-->

            <bic-select class="w-1/4" formControlName="branch" [label]="'Branche'" [required]="false">
              <ng-container *ngFor="let b of branch | keyvalue">
                <bic-dropdown-element [value]="b.value">{{b.value | branch}}</bic-dropdown-element>
              </ng-container>
            </bic-select>


<!--            <mat-form-field appearance="outline" class="flex w-1/4 h-12" subscriptSizing="dynamic">-->
<!--              <mat-label>Branche</mat-label>-->
<!--              <mat-select formControlName="branch">-->
<!--                <mat-option *ngFor="let b of branch | keyvalue" [value]="b.value">{{b.value | branch}}</mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->
          </div>
        </div>

      </div>

<!--      <div class="flex flex-row justify-between items-end pr-1.5" *ngIf="isInit">-->
<!--        <div class="w-[120px]">-->
<!--          <button title="Account löschen" mat-raised-button color="warn" (click)="openDeleteDialog()">Löschen</button>-->
<!--        </div>-->
<!--        <div class="w-[120px]">-->
<!--          <button mat-raised-button color="primary" (click)="save()">Speichern</button>-->
<!--        </div>-->
<!--      </div>-->
      <div class="flex flex-row justify-end items-center">
        <bic-button *ngIf="isInit" [text]="'Weiter'" [iconRight]="isInit ? faChevronRight : undefined" (onClick)=" save();addressPanel.open()"></bic-button>
        <bic-button *ngIf="!isInit" [text]="'Speichern'" [iconRight]="isInit ? faChevronRight : undefined" (onClick)=" save()"></bic-button>
      </div>
    </div>


  </form>
</ng-template>


<ng-template #addressInfo>
  <app-address-input *ngIf="addressInfoForm" [formGroup]="addressInfoForm"></app-address-input>

  <div class="flex flex-row justify-end items-center gap-2 pt-12">
    <bic-button *ngIf="isInit" [text]="'Weiter'" [iconRight]="isInit ? faChevronRight : undefined" [disabled]="!addressInfoForm.valid" (onClick)="saveAddress(); twoFaPanel.open()"></bic-button>
    <bic-button *ngIf="!isInit" [text]="'Speichern'" [iconRight]="isInit ? faChevronRight : undefined" [disabled]="!addressInfoForm.valid" (onClick)="saveAddress()"></bic-button>
  </div>

<!--  <div *ngIf="addressInfoForm" [formGroup]="addressInfoForm" class="pt-2 pb-4">-->
<!--    <div class="flex flex-col justify-start w-full gap-12">-->

<!--      &lt;!&ndash; content is one big column split into 2 rows + button row &ndash;&gt;-->
<!--      <div class="flex flex-col justify-start w-full gap-4">-->

<!--        &lt;!&ndash; first row &ndash;&gt;-->
<!--        <div class="flex flex-row justify-between h-[60px] w-full gap-[5%]">-->


<!--&lt;!&ndash;          <bic-input class="w-[68.5%]" formControlName="street" [label]="'Straße *'" [required]="true" (ngModelChange)="locationGuesses$ = serviceControllerService.getLocationSuggestions(street.value)">&ndash;&gt;-->
<!--&lt;!&ndash;          </bic-input>&ndash;&gt;-->

<!--&lt;!&ndash;          <ng-container *ngFor="let location of (locationGuesses$ | async) ?? []">&ndash;&gt;-->
<!--&lt;!&ndash;            <bic-dropdown-element (onClick)="updateLocation(location)" [value]="location.street">&ndash;&gt;-->
<!--&lt;!&ndash;              {{ location.street }} {{ location.addressNumber }}, {{ location.postalCode }} {{ location.municipality }}&ndash;&gt;-->
<!--&lt;!&ndash;            </bic-dropdown-element>&ndash;&gt;-->
<!--&lt;!&ndash;          </ng-container>&ndash;&gt;-->

<!--          &lt;!&ndash; street &ndash;&gt;-->
<!--&lt;!&ndash;          <mat-form-field appearance="outline" class="h-[50px] w-[68.5%]">&ndash;&gt;-->
<!--&lt;!&ndash;            <mat-label>Straße</mat-label>&ndash;&gt;-->
<!--&lt;!&ndash;            <input #street (ngModelChange)="locationGuesses$ = serviceControllerService.getLocationSuggestions(street.value)" [matAutocomplete]="autoStreet" formControlName="street" matInput&ndash;&gt;-->
<!--&lt;!&ndash;                   required>&ndash;&gt;-->
<!--&lt;!&ndash;            <mat-autocomplete #autoStreet="matAutocomplete">&ndash;&gt;-->
<!--&lt;!&ndash;              <mat-option (onSelectionChange)="updateLocation(location)" *ngFor="let location of (locationGuesses$ | async) ?? []"&ndash;&gt;-->
<!--&lt;!&ndash;                          [value]="location.street">&ndash;&gt;-->
<!--&lt;!&ndash;                {{ location.street }} {{ location.addressNumber }}&ndash;&gt;-->
<!--&lt;!&ndash;                , {{ location.postalCode }} {{ location.municipality }}&ndash;&gt;-->
<!--&lt;!&ndash;              </mat-option>&ndash;&gt;-->
<!--&lt;!&ndash;            </mat-autocomplete>&ndash;&gt;-->
<!--&lt;!&ndash;          </mat-form-field>&ndash;&gt;-->

<!--          &lt;!&ndash; houseNumber &ndash;&gt;-->
<!--          <bic-input class="w-[31.5%]" formControlName="houseNumber" [label]="'Hausnummer *'" [required]="true"></bic-input>-->

<!--&lt;!&ndash;          <mat-form-field appearance="outline" class="h-[50px] w-[31.5%]">&ndash;&gt;-->
<!--&lt;!&ndash;            <mat-label>Hausnummer</mat-label>&ndash;&gt;-->
<!--&lt;!&ndash;            <input formControlName="houseNumber" matInput required>&ndash;&gt;-->
<!--&lt;!&ndash;          </mat-form-field>&ndash;&gt;-->


<!--        </div>-->

<!--        &lt;!&ndash; second row &ndash;&gt;-->
<!--        <div class="flex flex-row justify justify-start h-[60px] w-full gap-[5%]">-->

<!--          &lt;!&ndash; zipCode &ndash;&gt;-->
<!--          <bic-input class="w-[33.3%]" formControlName="zipCode" [label]="'Postleitzahl *'" [required]="true"></bic-input>-->

<!--&lt;!&ndash;          <mat-form-field appearance="outline" class="h-full w-[33.3%]">&ndash;&gt;-->
<!--&lt;!&ndash;            <mat-label>Postleitzahl</mat-label>&ndash;&gt;-->
<!--&lt;!&ndash;            <input formControlName="zipCode" matInput maxlength="5" required>&ndash;&gt;-->
<!--&lt;!&ndash;          </mat-form-field>&ndash;&gt;-->

<!--          &lt;!&ndash; city / buildingPlace &ndash;&gt;-->
<!--          <bic-input class="w-[33.3%]" formControlName="city" [label]="'Ort *'" [required]="true"></bic-input>-->

<!--&lt;!&ndash;          <mat-form-field appearance="outline" class="h-[50px] w-[33.3%]">&ndash;&gt;-->
<!--&lt;!&ndash;            <mat-label>Ort</mat-label>&ndash;&gt;-->
<!--&lt;!&ndash;            <input formControlName="city" matInput required>&ndash;&gt;-->
<!--&lt;!&ndash;          </mat-form-field>&ndash;&gt;-->

<!--          &lt;!&ndash; country &ndash;&gt;-->
<!--          <bic-select class="w-[33.3%]" formControlName="country" [label]="'Land *'" [required]="true">-->
<!--            <ng-container *ngFor="let country of countries | sortCountries |keyvalue: valueAscOrder">-->
<!--              <bic-dropdown-element [value]="country.key">{{country.value}}</bic-dropdown-element>-->
<!--            </ng-container>-->
<!--          </bic-select>-->


<!--&lt;!&ndash;          <mat-form-field appearance="outline" class="h-[50px] w-[33.3%]">&ndash;&gt;-->
<!--&lt;!&ndash;            <mat-label>Land</mat-label>&ndash;&gt;-->
<!--&lt;!&ndash;            <mat-select formControlName="country">&ndash;&gt;-->
<!--&lt;!&ndash;              <mat-option *ngFor="let country of countries | sortCountries |keyvalue: valueAscOrder"&ndash;&gt;-->
<!--&lt;!&ndash;                          [value]="country.key">&ndash;&gt;-->
<!--&lt;!&ndash;                {{ country.value }}&ndash;&gt;-->
<!--&lt;!&ndash;              </mat-option>&ndash;&gt;-->
<!--&lt;!&ndash;            </mat-select>&ndash;&gt;-->
<!--&lt;!&ndash;          </mat-form-field>&ndash;&gt;-->


<!--        </div>-->

<!--        &lt;!&ndash; save button &ndash;&gt;-->
<!--        <div class="flex flex-row justify-end pt-6">-->
<!--          <div class="w-[120px]">-->
<!--            <button (click)="saveAddress()"-->
<!--                    color="primary"-->
<!--                    mat-raised-button>Speichern-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->


<!--    </div>-->

<!--  </div>-->
</ng-template>

<ng-template #twoFaData>
  <div class="flex flex-col w-full pt-12">
    <div class="flex flex-row w-full pb-6 items-center gap-1.5">
      <span class="text-base font-semibold text-gray-900">Zwei-Faktor-Authentifizierung</span>
<!--      <fa-icon [icon]="faCircleInfo" class="text-[#D1D5DB] text-sm" tooltip="Die Zwei-Faktor-Authentifizierung (2FA) bietet eine zusätzliche Sicherheitsebene für Ihre Anmeldung. Neben Ihrem Passwort benötigen Sie einen zweiten Bestätigungsschritt, z.B. einen Code aus einer Authentifizierungs-App. Diese Methode schützt Ihr Konto vor unbefugtem Zugriff, selbst wenn Ihr Passwort gestohlen wird."></fa-icon>-->
    </div>
    <div class="flex flex-row w-full justify-between pb-6">
      <div class="flex flex-col">
        <span class="text-gray-900 font-medium">E-Mail</span>
        <span class="text-gray-500">Verwende deine E-Mail Adresse um den Bestätigungscode zu empfangen.</span>
      </div>
      <label class="inline-flex items-center cursor-pointer">
        <input type="checkbox" [checked]="tfaMail" class="sr-only peer" id="tfaMail" (change)="handleChange($event)">
        <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-0 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></div>
<!--        <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Toggle me</span>-->
      </label>
    </div>
    <div class="flex flex-row w-full border border-gray-100"></div>
    <div class="flex flex-row w-full justify-between pt-6">
      <div class="flex flex-col">
        <span class="text-gray-900 font-medium">Authentifizierungs-App</span>
        <span class="text-gray-500">Verwende eine Authentifizierungs-App um den Bestätigungscode zu empfangen.</span>
      </div>
      <label class="inline-flex items-center cursor-pointer">
        <input type="checkbox" [checked]="tfaApp" class="sr-only peer" id="tfaApp" (change)="handleChange($event)">
        <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-0 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></div>
        <!--        <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Toggle me</span>-->
      </label>


    </div>
<!--    <div class="flex flex-row justify-end items-center gap-2 pt-12">-->
<!--      <bic-button [text]="isInit ? 'Abschließen' : 'Speichern'" [disabled]="is2faButtonDisabled()" [iconRight]="faChevronRight" (onClick)="openTfaPopup()"></bic-button>-->
<!--    </div>-->

  </div>
  <ng-container *ngIf="isInit">
    <div class="flex flex-row w-full border border-gray-100 my-6"></div>
    <div class="flex flex-row justify-end items-center">
      <bic-button [text]="'Abschließen'" [routerLink]="['/organisations']"></bic-button>
    </div>

    </ng-container>

</ng-template>

<ng-template #changePasswordData>
  <ng-container>
    <form [formGroup]="passwordForm">
      <div class="flex flex-col w-full gap-6">
        <div class="flex flex-row w-full pb-6 items-center gap-1.5">
          <span class="text-base font-semibold text-gray-900">Passwort</span>
        </div>
        <div class="flex flex-row w-full gap-8">
          <bic-input class="w-1/2" formControlName="currentPassword" [label]="'Altes Passwort *'" [isPassword]="true" [required]="true" [errorCondition]="passwordForm.controls.currentPassword.touched && passwordForm.controls.currentPassword.invalid"
                     (blur)="passwordForm.get('currentPassword')?.markAsTouched()" (focus)="passwordForm.get('currentPassword')?.markAsUntouched()" [(showPassword)]="showPasswordOld" [type]="'password'"></bic-input>
          <div class="w-1/2"></div>
        </div>
        <div class="flex flex-row w-full gap-8">
          <div class="flex flex-col w-1/2">
            <bic-input class="w-full " formControlName="newPassword" [label]="'Neues Passwort *'" [isPassword]="true" [required]="true" [errorCondition]="passwordForm.controls.newPassword.touched && passwordForm.controls.newPassword.invalid"
                       (blur)="passwordForm.get('newPassword')?.markAsTouched()" (focus)="passwordForm.get('newPassword')?.markAsUntouched()" [(showPassword)]="showPasswordNew" [type]="'password'"></bic-input>
            <ng-container *ngIf="passwordForm.get('newPassword')?.touched && passwordForm.get('newPassword')?.invalid">
              <ng-container *ngIf="!passwordForm.get('newPassword')?.errors?.required">
                <span class="text-xs text-error pt-1" *ngIf="!passwordForm.get('newPassword')?.valid">Das Passwort entspricht nicht den Sicherheitsvorgaben.</span>
              </ng-container>
            </ng-container>
          </div>

          <div class="flex flex-col w-1/2">
            <bic-input class="w-full" formControlName="newPasswordRepeat" [label]="'Neues Passwort wiederholen *'" [isPassword]="true" [required]="true" [errorCondition]="passwordForm.controls.newPasswordRepeat.touched && passwordForm.controls.newPasswordRepeat.invalid"
                       (blur)="passwordForm.get('newPasswordRepeat')?.markAsTouched()" (focus)="passwordForm.get('newPasswordRepeat')?.markAsUntouched()" [(showPassword)]="showPasswordNewRepeat" [type]="'password'"></bic-input>
            <ng-container *ngIf="passwordForm.get('newPasswordRepeat')?.touched && passwordForm.get('newPasswordRepeat')?.invalid">
              <ng-container *ngIf="passwordForm.get('newPasswordRepeat')?.errors?.matchValue != null">
                <span class="text-xs text-error pt-1" *ngIf="!passwordForm.get('newPasswordRepeat')?.errors?.matchValue && (passwordForm.get('newPassword')?.value != '' || passwordForm.get('newPasswordRepeat')?.value != '')">Die Passwörter stimmen nicht überein.</span>
              </ng-container>
            </ng-container>
          </div>
        </div>

      </div>
    </form>

    <div class="flex flex-col justify-start items-start w-[90%] pt-6">
      <span class="text-gray-900 text-sm font-semibold pb-1">Vorgaben Passwortsicherheit</span>
      <div class="flex flex-row items-center pl-4">
        <ul class="text-gray-900 text-xs list-disc ">
          <li [class]=" testLength(passwordForm.get('newPassword')?.value ?? '') ? 'text-primary' : 'text-gray-900'">Enthält mindestens 8 Zeichen</li>
          <li [class]=" testUpperCase(passwordForm.get('newPassword')?.value ?? '') && testLowerCase(passwordForm.get('newPassword')?.value ?? '') ? 'text-primary' : 'text-gray-900'">Ein Groß- und Kleinbuchstabe</li>
          <li [class]=" testNumeric(passwordForm.get('newPassword')?.value ?? '') ? 'text-primary' : 'text-gray-900'">Eine Zahl</li>
          <li [class]=" testSpecialCharacter(passwordForm.get('newPassword')?.value ?? '') ? 'text-primary' : 'text-gray-900'">Ein Sonderzeichen (!?&%=-.:*)</li>
        </ul>
      </div>
    </div>
    <div class="flex flex-row w-full border border-gray-100 my-6"></div>





<!--    <form [formGroup]="passwordForm">-->
<!--      <div class="flex flex-row">-->
<!--        <mat-form-field appearance="outline" class="flex w-full">-->
<!--          <mat-label>Altes Passwort</mat-label>-->
<!--          <input formControlName="currentPassword" matInput type="password" required>-->
<!--          <mat-error-->
<!--            *ngIf="passwordForm.controls.currentPassword.touched && passwordForm.controls.currentPassword.invalid">-->
<!--            &lt;!&ndash;                <span&ndash;&gt;-->
<!--            &lt;!&ndash;                  *ngIf="passwordForm.controls.currentPassword.errors?.required">Sie müssen Ihr altes Passwort angeben!</span>&ndash;&gt;-->
<!--          </mat-error>-->
<!--        </mat-form-field>-->
<!--      </div>-->
<!--      <div class="flex flex-row">-->
<!--        <mat-form-field appearance="outline" class="flex w-full">-->
<!--          <mat-label>Neues Passwort</mat-label>-->
<!--          <input formControlName="newPassword" matInput type="password" required>-->
<!--          <mat-error *ngIf="passwordForm.controls.newPassword.touched && passwordForm.controls.newPassword.invalid">-->
<!--            &lt;!&ndash;                <span&ndash;&gt;-->
<!--            &lt;!&ndash;                  *ngIf="passwordForm.controls.newPassword.errors?.required">Sie müssen ein neues Passwort angeben!</span>&ndash;&gt;-->
<!--          </mat-error>-->
<!--        </mat-form-field>-->
<!--      </div>-->
<!--      <div class="flex flex-row">-->
<!--        <mat-form-field appearance="outline" class="flex w-full">-->
<!--          <mat-label>Neues Passwort wiederholen</mat-label>-->
<!--          <input formControlName="newPasswordRepeat" matInput type="password" required>-->
<!--          <mat-error-->
<!--            *ngIf="passwordForm.controls.newPasswordRepeat.touched && passwordForm.controls.newPasswordRepeat.invalid">-->
<!--            &lt;!&ndash;                <span *ngIf="passwordForm.controls.newPasswordRepeat.errors?.required">Sie müssen Ihr neues Passwort wiederholen!</span>&ndash;&gt;-->
<!--          </mat-error>-->
<!--        </mat-form-field>-->
<!--      </div>-->
<!--    </form>-->
    <div class="flex flex-row justify-between items-center">
      <bic-button [text]="'Konto löschen'" [color]="'white'" [textColor]="'gray-900'" [border]="true" (onClick)="openDeleteDialog()"></bic-button>
      <bic-button [text]="'Speichern'" [disabled]="passwordForm.invalid" (onClick)="renewPassword()"></bic-button>
    </div>
  </ng-container>

</ng-template>
