/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { JsonArray } from './jsonArray';
import { JsonNull } from './jsonNull';
import { JsonPrimitive } from './jsonPrimitive';

export interface JsonObject { 
    empty?: boolean;
    asString?: string;
    asInt?: number;
    asDouble?: number;
    asLong?: number;
    asBoolean?: boolean;
    jsonArray?: boolean;
    jsonObject?: boolean;
    jsonPrimitive?: boolean;
    jsonNull?: boolean;
    asJsonArray?: JsonArray;
    asJsonPrimitive?: JsonPrimitive;
    asJsonNull?: JsonNull;
    asNumber?: number;
    asFloat?: number;
    asByte?: string;
    asCharacter?: string;
    asBigDecimal?: number;
    asBigInteger?: number;
    asShort?: number;
    asJsonObject?: JsonObject;
}