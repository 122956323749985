import {Pipe, PipeTransform} from '@angular/core';
import {QualityCheck} from "../api";
import CheckTypeEnum = QualityCheck.CheckTypeEnum;

@Pipe({
  name: 'checkType'
})
export class CheckTypePipe implements PipeTransform {

  transform(value: CheckTypeEnum): string {
    switch (value) {
      case "QUALITY":
        return "Qualitäts-Check";
      case "HOAI":
        return "HOAI-Check";
      case "IFC":
        return "LOI-Check";
      case "BASE":
        return "BIM-Basis-Check";
      case "SWM":
        return "SWM-Check";
    }
    return "Unbekannter Check";
  }
}
