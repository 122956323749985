<div *ngIf="!create" class="mt-2 h-full w-full overflow-y-scroll px-4 pb-2 pt-8">

  <mat-accordion>

    <!-- panel: general project info -->
    <mat-expansion-panel class="py-2 px-4" *ngIf="true" expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="text-[20px] text-secondary">Projekt: {{ project.name }}</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="generalInfo"></ng-container>
    </mat-expansion-panel>

    <!-- panel:  project address info -->
    <mat-expansion-panel class="py-2 px-4">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="text-[20px] text-secondary">Projektadresse</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="addressInfo"></ng-container>
    </mat-expansion-panel>

    <!-- panel:  project planning and builder info -->
    <mat-expansion-panel class="py-2 px-4">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="text-[20px] text-secondary">Planung & Bauherr</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="builderInfo"></ng-container>
    </mat-expansion-panel>

    <!-- panel:  project members info -->
    <mat-expansion-panel class="py-2 px-4">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="text-[20px] text-secondary">Projektmitglieder</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="pb-4">
        <app-users-list [fromProjectAccordion]="true" [project]="stateConfigService.getProject()!" [isProject]="true"
                        [organisation]="stateConfigService.getOrganisation()"
                        [addDisabled]="!permissionService.hasProjectPrivilege('PROJECT_USER_MANAGEMENT')"
                        [deleteDisabled]="!permissionService.hasProjectPrivilege('PROJECT_USER_MANAGEMENT')"
                        [permissionsDisabled]="!permissionService.hasProjectPrivilege('PROJECT_USER_MANAGEMENT')">
        </app-users-list>
      </div>
    </mat-expansion-panel>

    <!-- panel:  project settings info -->
    <mat-expansion-panel class="py-2 px-4">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="text-[20px] text-secondary">Projekteinstellungen</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="qualitySettingsInfo"></ng-container>
    </mat-expansion-panel>

  </mat-accordion>

</div>

<ng-template #generalInfo>
  <form [formGroup]="generalInfoForm">
    <div class="flex flex-col justify-start gap-12">

      <!-- content row split into 3 columns-->
      <div class="flex flex-row justify-center pl-2 gap-16">

        <!-- project image -->
        <div class="flex flex-row justify-center items-center">
          <app-project-image-upload *ngIf="project" [(image)]="project.image" [showOnly]="!editImagePermission"
                                    [project]="project"></app-project-image-upload>
        </div>

        <!-- inputs -->
        <div class="flex flex-row w-full justify-between gap-12">
          <!-- name and id column -->
          <div class="flex flex-col justify-between h-full w-1/2 gap-4">
            <bic-input [formControlName]="'name'" [label]="'Projektname *'" [placeholder]="'Eingeben'" class="w-full" [required]="true" [errorCondition]="generalInfoForm.controls.name.touched && generalInfoForm.controls.name.invalid"></bic-input>


<!--            <mat-form-field appearance="outline" class="flex flex-row h-12 w-full" subscriptSizing="dynamic">-->
<!--              <mat-label>Projektname</mat-label>-->
<!--              <input formControlName="name" matInput required (keydown.enter)="$event.target.blur()">-->
<!--              <mat-error *ngIf="generalInfoForm.controls.name.touched && generalInfoForm.controls.name.invalid">-->
<!--                          <span-->
<!--                            *ngIf="generalInfoForm.controls.name.errors?.required">Sie müssen dem Projekt einen Namen geben!</span>-->
<!--              </mat-error>-->
<!--            </mat-form-field>-->

            <bic-input [formControlName]="'projectIdIntern'" [label]="'Projekt ID'" [placeholder]="'Eingeben'" class="w-full"></bic-input>

<!--            <div class="w-full flex flex-row justify-between gap-4">-->
<!--              <mat-form-field appearance="outline" class="flex flex-row h-12 w-full" subscriptSizing="dynamic">-->
<!--                <mat-label>Projekt ID</mat-label>-->
<!--                <input formControlName="projectIdIntern" matInput (keydown.enter)="$event.target.blur()">-->
<!--              </mat-form-field>-->


<!--            </div>-->

          </div>

          <!-- owner and project kind column -->
          <div class="flex flex-col justify-between h-full w-1/2 gap-4">
            <bic-input [formControlName]="'ownerOrganisation'" [label]="'Projektbesitzer*in'" [placeholder]="'Eingeben'" class="w-full" [disabled]="true"></bic-input>
<!--            <mat-form-field appearance="outline" class="flex-row h-12 w-full" subscriptSizing="dynamic">-->
<!--              <mat-label>Projektbesitzer*in</mat-label>-->
<!--              <input formControlName="ownerOrganisation" matInput>-->
<!--            </mat-form-field>-->

            <bic-select [formControlName]="'isBIM'" [label]="'BIM Projekt ?'">
              <bic-dropdown-element [value]="true">Ja</bic-dropdown-element>
              <bic-dropdown-element [value]="false">Nein</bic-dropdown-element>
            </bic-select>



<!--            <mat-form-field appearance="outline" class="flex flex-row h-12 w-full" subscriptSizing="dynamic">-->
<!--              <mat-label>BIM Projekt ?</mat-label>-->
<!--              <mat-select formControlName="isBIM">-->
<!--                <mat-option [value]="true">-->
<!--                  Ja-->
<!--                </mat-option>-->
<!--                <mat-option [value]="false">-->
<!--                  Nein-->
<!--                </mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->
          </div>
        </div>
      </div>

      <!-- delete and save buttons -->
      <div class="flex flex-row justify-between">
        <bic-button [text]="'Löschen'" [color]="'error'" [disabled]="!permissionService.hasProjectPrivilege('PROJECT_SETTINGS')" (onClick)="openDeleteDialog()"></bic-button>
        <bic-button [text]="'Speichern'" [disabled]="!permissionService.hasProjectPrivilege('PROJECT_SETTINGS') || saveDisabled" (onClick)="save()"></bic-button>



<!--        <div class="w-[120px]">-->
<!--          <button-->
<!--            [matTooltip]="permissionService.hasProjectPrivilege('PROJECT_SETTINGS') ? 'Projekt löschen' :  'Keine Berechtigung' "-->
<!--            mat-raised-button color="warn" (click)="openDeleteDialog()"-->
<!--            [disabled]="!permissionService.hasProjectPrivilege('PROJECT_SETTINGS')">Löschen-->
<!--          </button>-->
<!--        </div>-->
<!--        <div class="w-[120px]">-->
<!--          <button mat-raised-button color="primary" (click)="save()"-->
<!--                  [disabled]="!permissionService.hasProjectPrivilege('PROJECT_SETTINGS') || saveDisabled">Speichern-->
<!--          </button>-->
<!--        </div>-->
      </div>

    </div>
  </form>
</ng-template>


<ng-template #addressInfo>

  <div class="flex flex-col justify-start w-full gap-12">
    <app-address-input
      formControlNameZipcode="zipCode"
      formControlNameCity="buildingPlace"
      formControlNameHouseNumber="houseNumber"
      [stateDisabled]="!hasEditPermission"
      [displayState]="true"
      [displayPlotNumber]="true"
      [formGroup]="addressInfoForm"></app-address-input>

    <div class="flex flex-row justify-end pt-6">
      <bic-button [text]="'Speichern'" [disabled]="!permissionService.hasProjectPrivilege('PROJECT_SETTINGS') || saveDisabled" (onClick)="save()"></bic-button>
    </div>
  </div>


  <!--  <form [formGroup]="addressInfoForm">
      <div class="flex flex-col justify-start w-full gap-12">

        &lt;!&ndash; content is one big column split into 2 rows + button row &ndash;&gt;
        <div class="flex flex-col justify-start w-full gap-4">

          &lt;!&ndash; first row &ndash;&gt;
          <div class="flex flex-row justify-between h-[60px] w-full gap-[5%]">

            &lt;!&ndash; street &ndash;&gt;
            <mat-form-field appearance="outline" class="h-[50px] w-[45%]">
              <mat-label>Straße</mat-label>
              <input #street required formControlName="street" matInput [matAutocomplete]="autoStreet" (ngModelChange)="locationGuesses$ = serviceControllerService.getLocationSuggestions(street.value)">
              <mat-autocomplete #autoStreet="matAutocomplete">
                <mat-option *ngFor="let location of (locationGuesses$ | async) ?? []" [value]="location.street" (onSelectionChange)="updateLocation(location)">
                  {{location.street}} {{location.addressNumber}}, {{location.postalCode}} {{location.municipality}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            &lt;!&ndash; houseNumber &ndash;&gt;
            <mat-form-field appearance="outline" class="h-[50px] w-[22%]">
              <mat-label>Hausnummer</mat-label>
              <input required formControlName="houseNumber" matInput>
            </mat-form-field>

            &lt;!&ndash; plotNumber &ndash;&gt;
            <mat-form-field appearance="outline" class="h-[50px] w-[22%]">
              <mat-label>Flurstücksnummer</mat-label>
              <input matInput formControlName="plotNumber">
            </mat-form-field>

          </div>

          &lt;!&ndash; second row &ndash;&gt;
          <div class="flex flex-row justify justify-between h-[60px] w-full gap-[5%]">

            &lt;!&ndash; zipCode &ndash;&gt;
            <mat-form-field appearance="outline" class="h-full w-[19.75%]">
              <mat-label>Postleitzahl</mat-label>
              <input required formControlName="zipCode" matInput maxlength="5">
            </mat-form-field>

            &lt;!&ndash; city / buildingPlace &ndash;&gt;
            <mat-form-field appearance="outline" class="h-[50px] w-[19.75%]">
              <mat-label>Ort</mat-label>
              <input required formControlName="buildingPlace" matInput>
            </mat-form-field>

            &lt;!&ndash; country &ndash;&gt;
            <mat-form-field appearance="outline" class="h-[50px] w-[22%]">
              <mat-label>Land</mat-label>
              <mat-select formControlName="country">
                <mat-option *ngFor="let country of countries | sortCountries |keyvalue: valueAscOrder" [value]="country.key">
                  {{country.value}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            &lt;!&ndash; state &ndash;&gt;
            <mat-form-field appearance="outline" class="h-[50px] w-[22%]">
              <mat-label>Bundesland</mat-label>
              <mat-select formControlName="state">
                <mat-option *ngFor="let state of states | keyvalue" [value]="state.value">
                  {{state.value | projectState}}
                </mat-option>
              </mat-select>
            </mat-form-field>


          </div>

          &lt;!&ndash; save button &ndash;&gt;
          <div class="flex flex-row justify-end pt-6">
            <div class="w-[120px]">
              <button mat-raised-button color="primary" (click)="save()"
                      [disabled]="!permissionService.hasProjectPrivilege('PROJECT_SETTINGS') || saveDisabled">Speichern</button>
            </div>
          </div>
        </div>


      </div>

    </form>-->
</ng-template>


<ng-template #builderInfo>
  <form [formGroup]="builderInfoForm">
    <div class="flex flex-col justify-start w-full gap-12">

      <!-- content is one big column split into 2 rows + save button -->
      <div class="flex flex-col justify-start w-full gap-6">

        <!-- first row -->
        <div class="flex flex-row justify justify-between h-[60px] w-full gap-[5%]">

          <bic-select [formControlName]="'builderSalutation'" [label]="'Anrede'" class="w-[25%]">
            <bic-dropdown-element [value]="salutation.HERR">Herr</bic-dropdown-element>
            <bic-dropdown-element [value]="salutation.FRAU">Frau</bic-dropdown-element>
            <bic-dropdown-element [value]="salutation.NONE">Keine</bic-dropdown-element>
          </bic-select>

          <!-- builderSalutation -->
<!--          <mat-form-field appearance="outline" class="h-[50px] w-[30%]">-->
<!--            <mat-label>Anrede</mat-label>-->
<!--            <mat-select formControlName="builderSalutation">-->
<!--              <mat-option [value]="salutation.HERR">{{ SalutationEnum.HERR | salutation }}</mat-option>-->
<!--              <mat-option [value]="salutation.FRAU">{{ SalutationEnum.FRAU | salutation }}</mat-option>-->
<!--              <mat-option [value]="salutation.NONE">{{ SalutationEnum.NONE | salutation }}</mat-option>-->
<!--            </mat-select>-->
<!--            &lt;!&ndash;            <input required formControlName="builderSalutation" [value]="project.builderSalutation" matInput>&ndash;&gt;-->
<!--          </mat-form-field>-->

          <!-- builderTitle not needed -->
          <!--          <mat-form-field appearance="outline" class="h-[50px] w-[325px]">-->
          <!--            <mat-label>Titel</mat-label>-->
          <!--            <mat-select formControlName="title">-->
          <!--              <mat-option [value]="title.NONE" >{{TitleEnum.NONE | title}}</mat-option>-->
          <!--              <mat-option [value]="title.DR" >{{TitleEnum.DR | title}}</mat-option>-->
          <!--              <mat-option [value]="title.PROF" >{{TitleEnum.PROF | title}}</mat-option>-->
          <!--              <mat-option [value]="title.PROFDR" >{{TitleEnum.PROFDR | title}}</mat-option>-->
          <!--            </mat-select>-->
          <!--            <input required formControlName="builderTitle" [value]="this.stateConfigService.selectedProject.builderTitle" matInput>-->
          <!--          </mat-form-field>-->


          <bic-input [formControlName]="'builderFirstName'" [value]="this.stateConfigService.selectedProject.builderFirstName" [label]="'Vorname'" class="w-[25%]"></bic-input>


          <!-- builderFirstName -->
<!--          <mat-form-field appearance="outline" class="h-[50px] w-[30%]">-->
<!--            <mat-label>Vorname</mat-label>-->
<!--            <input formControlName="builderFirstName" [value]="this.stateConfigService.selectedProject.builderFirstName"-->
<!--                   matInput>-->
<!--          </mat-form-field>-->

          <bic-input [formControlName]="'builderLastName'" [value]="this.stateConfigService.selectedProject.builderLastName" [label]="'Nachname'" class="w-[25%]"></bic-input>

          <!-- builderLastName -->
<!--          <mat-form-field appearance="outline" class="h-[50px] w-[30%]">-->
<!--            <mat-label>Nachname</mat-label>-->
<!--            <input formControlName="builderLastName" [value]="this.stateConfigService.selectedProject.builderLastName"-->
<!--                   matInput>-->
<!--          </mat-form-field>-->

          <bic-input [formControlName]="'software'" [value]="this.stateConfigService.selectedProject.software" [label]="'Planungssoftware'" class="w-[25%]"></bic-input>
        </div>


        <!-- second row -->
        <div class="flex flex-row justify justify-between h-[60px] w-full gap-[5%]">
          <div bicDatepickerRangeInput class="flex flex-row gap-[10%] w-1/2" (datesChange)="builderInfoForm.get('beginPlanning')?.setValue($event[0]); builderInfoForm.get('endPlanning')?.setValue($event[1])">
            <bic-input [iconStart]="faCalendarDays"  formControlName="beginPlanning" [label]="'Planungsstart'" [isDatepicker]="true"
                       class="w-full"></bic-input>
            <bic-input [iconStart]="faCalendarDays"  formControlName="endPlanning" [label]="'Planungsende'" [isDatepicker]="true"
                       class="w-full"></bic-input>
          </div>



          <!-- planningTime -->
<!--          <mat-form-field appearance="outline" class="h-[50px] w-[30%]">-->
<!--            <mat-label>Planungszeitraum</mat-label>-->
<!--            <mat-date-range-input [rangePicker]="planningPicker">-->
<!--              <input formControlName="beginPlanning" matStartDate placeholder="Start">-->
<!--              <input formControlName="endPlanning" matEndDate placeholder="Ende">-->
<!--            </mat-date-range-input>-->
<!--            <mat-datepicker-toggle [for]="planningPicker" matSuffix></mat-datepicker-toggle>-->
<!--            <mat-date-range-picker #planningPicker></mat-date-range-picker>-->
<!--            <mat-error *ngIf="timeForm.controls.beginPlanning.hasError('matStartDateInvalid')">Ungültiges-->
<!--              Startdatum-->
<!--            </mat-error>-->
<!--            <mat-error *ngIf="timeForm.controls.endPlanning.hasError('matEndDateInvalid')">Ungültiges Enddatum-->
<!--            </mat-error>-->
<!--          </mat-form-field>-->

          <!-- executingTime -->

        <div bicDatepickerRangeInput class="flex flex-row gap-[10%] w-1/2" (datesChange)="builderInfoForm.get('startExecuting')?.setValue($event[0]); builderInfoForm.get('endExecuting')?.setValue($event[1])">
          <bic-input [iconStart]="faCalendarDays"  formControlName="startExecuting" [label]="'Ausführungsstart'" [isDatepicker]="true"
                     class="w-full"></bic-input>
          <bic-input [iconStart]="faCalendarDays"  formControlName="endExecuting" [label]="'Ausführungsende'" [isDatepicker]="true"
                     class="w-full"></bic-input>
        </div>



<!--          <mat-form-field appearance="outline" class="h-[50px] w-[30%]">-->
<!--            <mat-label>Ausführungszeitraum</mat-label>-->
<!--            <mat-date-range-input [rangePicker]="executingPicker">-->
<!--              <input formControlName="startExecuting" matStartDate placeholder="Start">-->
<!--              <input formControlName="endExecuting" matEndDate placeholder="Ende">-->
<!--            </mat-date-range-input>-->
<!--            <mat-datepicker-toggle [for]="executingPicker" matSuffix></mat-datepicker-toggle>-->
<!--            <mat-date-range-picker #executingPicker></mat-date-range-picker>-->
<!--            <mat-error *ngIf="timeForm.controls.startExecuting.hasError('matStartDateInvalid')">Ungültiges-->
<!--              Startdatum-->
<!--            </mat-error>-->
<!--            <mat-error *ngIf="timeForm.controls.endExecuting.hasError('matEndDateInvalid')">Ungültiges Enddatum-->
<!--            </mat-error>-->
<!--          </mat-form-field>-->

          <!-- planning software -->
<!--          <mat-form-field appearance="outline" class="h-[50px] w-[30%]">-->
<!--            <mat-label>Planungssoftware</mat-label>-->
<!--            <input formControlName="software" matInput>-->
<!--          </mat-form-field>-->
        </div>
        <!--        &lt;!&ndash; second row &ndash;&gt;-->
        <!--        <div class="flex flex-row justify justify-between h-[60px] w-full gap-6">-->

        <!--          &lt;!&ndash; builderPostalCode &ndash;&gt;-->
        <!--          <mat-form-field appearance="outline" class="h-[50px] w-[325px]">-->
        <!--            <mat-label>Postleitzahl</mat-label>-->
        <!--            <input required formControlName="builderZipCode" [value]="this.stateConfigService.selectedProject.builderZipCode" matInput>-->
        <!--          </mat-form-field>-->

        <!--          &lt;!&ndash; builderCity &ndash;&gt;-->
        <!--          <mat-form-field appearance="outline" class="h-[50px] w-[325px]">-->
        <!--            <mat-label>Ort</mat-label>-->
        <!--            <input required formControlName="builderCity" [value]="this.stateConfigService.selectedProject.builderCity" matInput>-->
        <!--          </mat-form-field>-->

        <!--          &lt;!&ndash; builderCountry &ndash;&gt;-->
        <!--          <mat-form-field appearance="outline" class="h-[50px] w-[325px]">-->
        <!--            <mat-label>Land</mat-label>-->
        <!--            <input required formControlName="builderCountry" [value]="this.stateConfigService.selectedProject.builderCountry" matInput>-->
        <!--          </mat-form-field>-->

        <!--          &lt;!&ndash; builderState &ndash;&gt;-->
        <!--          <mat-form-field appearance="outline" class="h-[50px] w-[325px]">-->
        <!--            <mat-label>Bundesland</mat-label>-->
        <!--            <input required formControlName="builderState" [value]="this.stateConfigService.selectedProject.builderState" matInput>-->
        <!--          </mat-form-field>-->
        <!--        </div>-->

        <!-- save button -->
        <div class="flex flex-row justify-end pt-6">
          <bic-button [text]="'Speichern'" [disabled]="!permissionService.hasProjectPrivilege('PROJECT_SETTINGS') || saveDisabled" (onClick)="save()"></bic-button>
        </div>

      </div>
    </div>
  </form>
</ng-template>

<ng-template #qualitySettingsInfo>
  <form [formGroup]="qualitySettingsInfoForm">
    <div class="flex flex-col justify-start w-full gap-12">


      <!-- create rows, 1 row per 2 quality params -->
      <div *ngFor="let parameterList of qualityParams" class="flex flex-row h-60[px] w-full gap-[10%]"
           [ngClass]="{'justify-start' : parameterList.length == 1, 'justify-between' : parameterList.length != 1}">

        <ng-container *ngFor="let parameter of parameterList">
          <ng-container *ngIf="parameter.type == Parameter.TypeEnum.BOOL">
            <bic-select #boolSelect [value]="qualityValue[parameter.value]" (valueChange)="qualityValue[parameter.value] = boolSelect.value" [label]="parameter.name" class="w-[45%]">
              <bic-dropdown-element [value]="true">Ja</bic-dropdown-element>
              <bic-dropdown-element [value]="false">Nein</bic-dropdown-element>
            </bic-select>
          </ng-container>

          <ng-container *ngIf="parameter.type == Parameter.TypeEnum.INT">
            <bic-input #numberInput [value]="qualityValue[parameter.value]" (valueChange)="qualityValue[parameter.value] = numberInput.value"
                       (input)="validateNumberInput(numberInput, parameter.value)" [label]="parameter.name" [placeholder]="'Eingeben'" class="w-[45%]"></bic-input>
          </ng-container>

          <ng-container *ngIf="parameter.type == Parameter.TypeEnum.ENUM">
            <bic-select #enumSelect [value]="qualityValue[parameter.value]" (valueChange)="qualityValue[parameter.value] = enumSelect.value" [label]="parameter.name" class="w-[45%]">
              <bic-dropdown-element *ngFor="let value of parameter.enums" [value]="value.value">{{ value.name }}</bic-dropdown-element>
            </bic-select>
          </ng-container>

          <ng-container *ngIf="parameter.type == Parameter.TypeEnum.FLOAT || parameter.type == null">
            <bic-input #numberInput [value]="qualityValue[parameter.value]" (valueChange)="qualityValue[parameter.value] = numberInput.value"
                       (input)="validateNumberInput(numberInput, parameter.value)" [label]="parameter.name" [placeholder]="'Eingeben'" class="w-[45%]"></bic-input>
          </ng-container>


        </ng-container>



<!--        <mat-form-field appearance="outline" class="flex-row h-[50px] w-[45%]" *ngFor="let parameter of parameterList">-->
<!--          <mat-label>{{ parameter.name }}</mat-label>-->

<!--          <ng-container *ngIf="parameter.type == Parameter.TypeEnum.BOOL">-->
<!--            <mat-select #boolSelect [value]="qualityValue[parameter.value]"-->
<!--                        (valueChange)="qualityValue[parameter.value] = boolSelect.value">-->
<!--              <mat-option [value]="true">Ja</mat-option>-->
<!--              <mat-option [value]="false">Nein</mat-option>-->
<!--            </mat-select>-->
<!--          </ng-container>-->

<!--          <ng-container *ngIf="parameter.type == Parameter.TypeEnum.INT">-->
<!--            <input #numberInput [value]="qualityValue[parameter.value]"-->
<!--                   (change)="qualityValue[parameter.value] = numberInput.value"-->
<!--                   (input)="validateNumberInput(numberInput, parameter.value)"-->
<!--                   matInput type="number"/>-->
<!--            <mat-error *ngIf="qualitySettingsInfoForm.get(parameter.value)?.errors">-->
<!--              Nicht valide Eingabe-->
<!--            </mat-error>-->
<!--          </ng-container>-->

<!--          <ng-container *ngIf="parameter.type == Parameter.TypeEnum.ENUM">-->
<!--            <mat-select #enumSelect [value]="qualityValue[parameter.value]"-->
<!--                        (valueChange)="qualityValue[parameter.value] = enumSelect.value">-->
<!--              <mat-option *ngFor="let value of parameter.enums" [value]="value.value">{{ value.name }}</mat-option>-->
<!--            </mat-select>-->
<!--          </ng-container>-->

<!--          <ng-container *ngIf="parameter.type == Parameter.TypeEnum.FLOAT || parameter.type == null">-->
<!--            <input #numberInput [value]="qualityValue[parameter.value]"-->
<!--                   (change)="qualityValue[parameter.value] = numberInput.value"-->
<!--                   (input)="validateNumberInput(numberInput, parameter.value)"-->
<!--                   matInput type="number"/>-->
<!--            <mat-error *ngIf="qualitySettingsInfoForm.get(parameter.value)?.errors">-->
<!--              Nicht valide Eingabe-->
<!--            </mat-error>-->
<!--          </ng-container>-->


<!--        </mat-form-field>-->


      </div>


      <!-- save button -->
      <div class="flex flex-row justify-end pt-6">
        <bic-button [text]="'Speichern'" [disabled]="!permissionService.hasProjectPrivilege('PROJECT_SETTINGS')" (onClick)="save()"></bic-button>
      </div>

      <!--      </div>-->
    </div>
  </form>
</ng-template>


<div *ngIf="create" class="w-full h-full flex-col items-start justify-start p-6">
  <!--  <div *ngIf="!create" class="h-full overflow-hidden">-->
  <!--    <div class="flex flex-col justify-start gap-4 h-full overflow-y-scroll p-2">-->
  <!--      <div>-->
  <!--        <ng-container *ngTemplateOutlet="generalData"></ng-container>-->
  <!--        <ng-container *ngTemplateOutlet="placeData"></ng-container>-->
  <!--        <ng-container *ngTemplateOutlet="timeData"></ng-container>-->
  <!--      </div>-->
  <!--      <div class="flex flex-row justify-end items-end">-->
  <!--        <button mat-raised-button color="primary" (click)="save()" [disabled]="!permissionService.hasProjectPrivilege('PROJECT_SETTINGS')">Speichern</button>-->

  <!--      </div>-->
  <!--      <div class="flex flex-col justify-start gap-8">-->
  <!--        &lt;!&ndash;      <app-bic-table [project]="stateConfigService.getProject()!" [isProject]="true" [permissionService]="permissionService"></app-bic-table>&ndash;&gt;-->
  <!--        <app-users-list  [project]="stateConfigService.getProject()!" [isProject]="true" [organisation]="stateConfigService.getOrganisation()"-->
  <!--                                  [addDisabled]="!permissionService.hasProjectPrivilege('PROJECT_USER_MANAGEMENT')"-->
  <!--                                  [deleteDisabled]="!permissionService.hasProjectPrivilege('PROJECT_USER_MANAGEMENT')"-->
  <!--                                  [permissionsDisabled]="!permissionService.hasProjectPrivilege('PROJECT_USER_MANAGEMENT')">-->
  <!--        </app-users-list>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->

  <div *ngIf="create && user && organisations">
    <mat-accordion>
      <mat-expansion-panel class="p-6" expanded #ownerPanel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="flex flex-row items-center gap-2.5" [class]="ownerPanel.expanded ? 'text-primary' : 'text-secondary'">
              <div class="w-8 h-8 rounded-[50px] border justify-center items-center gap-2.5 inline-flex" [class]="ownerPanel.expanded ? 'border-primary' : 'border-secondary'">
                <div class="text-[#0c0f0a] text-sm font-medium font-['Inter'] leading-[17.50px]" [class]="ownerPanel.expanded ? 'text-primary' : 'text-secondary'">1</div>
              </div>
              <span class="text-xl">Besitzer*in</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
<!--        <ng-container *ngTemplateOutlet="ownerData"></ng-container>-->
        <ng-container *ngTemplateOutlet="generalData"></ng-container>
<!--        <div class="flex flex-row justify-end items-center gap-2">-->
<!--          <button mat-raised-button (click)="previousStep()">Zurück</button>-->
<!--          <button color="primary" mat-raised-button (click)="nextStep()" [disabled]="!generalForm.valid">Weiter</button>-->
<!--        </div>-->
        <div class="flex flex-row justify-end items-center gap-2 pt-12">
          <bic-button [text]="'Weiter'" [iconRight]="faChevronRight" [disabled]="!generalForm.valid || !ownerForm.valid" (onClick)="locationPanel.open()"></bic-button>
        </div>
      </mat-expansion-panel>

<!--      <mat-expansion-panel [expanded]="stepGeneral">-->
<!--        <mat-expansion-panel-header>-->
<!--          <mat-panel-title>Allgemeine Infos</mat-panel-title>-->
<!--        </mat-expansion-panel-header>-->
<!--        -->
<!--      </mat-expansion-panel>-->

      <mat-expansion-panel class="p-6" #locationPanel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="flex flex-row items-center gap-2.5" [class]="locationPanel.expanded ? 'text-primary' : 'text-secondary'">
              <div class="w-8 h-8 rounded-[50px] border justify-center items-center gap-2.5 inline-flex" [class]="locationPanel.expanded ? 'border-primary' : 'border-secondary'">
                <div class="text-[#0c0f0a] text-sm font-medium font-['Inter'] leading-[17.50px]" [class]="locationPanel.expanded ? 'text-primary' : 'text-secondary'">2</div>
              </div>
              <span class="text-xl">Bauort</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngTemplateOutlet="placeData"></ng-container>
        <div class="flex flex-row justify-end items-center gap-2 pt-12">
          <bic-button [text]="'Weiter'" [iconRight]="faChevronRight" [disabled]="!placeForm.valid" (onClick)="timePanel.open()"></bic-button>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel class="p-6" #timePanel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="flex flex-row items-center gap-2.5" [class]="timePanel.expanded ? 'text-primary' : 'text-secondary'">
              <div class="w-8 h-8 rounded-[50px] border justify-center items-center gap-2.5 inline-flex" [class]="timePanel.expanded ? 'border-primary' : 'border-secondary'">
                <div class="text-[#0c0f0a] text-sm font-medium font-['Inter'] leading-[17.50px]" [class]="timePanel.expanded ? 'text-primary' : 'text-secondary'">3</div>
              </div>
              <span class="text-xl">Zeitplan</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngTemplateOutlet="timeData"></ng-container>
        <div class="flex flex-row justify-end items-center gap-2 pt-12">
          <bic-button [text]="'Erstellen'" [iconRight]="faChevronRight" (onClick)="createProject()" [disabled]="!timeForm.valid"></bic-button>
        </div>
<!--        <div class="flex flex-row justify-end items-center gap-2">-->
<!--          <button mat-raised-button (click)="previousStep()">Zurück</button>-->
<!--          <button color="primary" mat-raised-button (click)="createProject()" [disabled]="!timeForm.valid">Weiter</button>-->
<!--        </div>-->
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <ng-template #ownerData>
    <div [formGroup]="ownerForm">
      <bic-select [formControlName]="'ownerOrganisation'" [label]="'Projektbesitzer*in'">
        <bic-dropdown-element [value]="-1">Persönlich ({{ user.firstName }} {{ user.lastName }})</bic-dropdown-element>
        <ng-container *ngFor="let organisation of organisations">
          <bic-dropdown-element [value]="organisation.id">{{organisation.name}}</bic-dropdown-element>
        </ng-container>
      </bic-select>

    </div>


<!--    HIER FEHLT NOCH DISABLED-->

<!--    <form [formGroup]="ownerForm" class="pt-2">-->
<!--      <mat-form-field appearance="outline" class="flex w-full">-->
<!--        <mat-label>Projektbesitzer*in</mat-label>-->
<!--        <mat-select formControlName="ownerOrganisation">-->
<!--          <mat-option [value]="-1">Persönlich ({{ user.firstName }} {{ user.lastName }})</mat-option>-->
<!--          <mat-option *ngFor="let organisation of organisations" [value]="organisation.id"-->
<!--                      [disabled]="!permissionService.hasOrganisationPrivilege('ORGANISATION_PROJECT_EDIT',organisation.id)"-->
<!--                      [class.disabled]="!permissionService.hasOrganisationPrivilege('ORGANISATION_PROJECT_EDIT',organisation.id)"-->
<!--                      [matTooltip]="!permissionService.hasOrganisationPrivilege('ORGANISATION_PROJECT_EDIT',organisation.id) ? 'Keine Berechtigung für dieses Unternehmen' : ''">-->
<!--            {{ organisation.name }}-->
<!--          </mat-option>-->
<!--        </mat-select>-->
<!--      </mat-form-field>-->
<!--    </form>-->
  </ng-template>

  <ng-template #generalData>
    <form [formGroup]="generalForm" class="pt-2">
      <div class="flex flex-row justify-between">
        <app-project-image-upload *ngIf="project" [(image)]="project.image" [showOnly]="!editImagePermission"
                                  [project]="project"></app-project-image-upload>

        <div class="flex flex-col w-full gap-6">
          <div class="flex flex-row gap-12">
            <div [formGroup]="ownerForm" *ngIf="create && organisationLoaded" class="w-1/2">
              <bic-select [formControlName]="'ownerOrganisation'" [label]="'Projektbesitzer*in'" class="w-full" [placeholder]="'Person oder Organisation auswählen'">
                <bic-dropdown-element [value]="-1">Persönlich ({{ user.firstName }} {{ user.lastName }})</bic-dropdown-element>
                <ng-container *ngIf="organisations.length > 0">
                  <ng-container *ngFor="let organisation of organisations; index as i">
                    <bic-dropdown-element [value]="organisation.id">{{organisation.name}}</bic-dropdown-element>
                  </ng-container>
                </ng-container>
<!--                <bic-dropdown-element *ngFor="let organisation of organisations; index as i" [value]="organisation?.id">{{organisation.name}}</bic-dropdown-element>-->

              </bic-select>

            </div>

            <bic-input [formControlName]="'name'" [label]="'Projektname *'" [required]="true" [placeholder]="'Name eingeben'" [class]="create ? 'w-1/2' : 'w-full'"></bic-input>


<!--            <mat-form-field appearance="outline" class="flex w-full">-->
<!--              <mat-label>Projektname</mat-label>-->
<!--              <input #name formControlName="name" matInput required>-->
<!--              <mat-error *ngIf="generalForm.controls.name.touched && generalForm.controls.name.invalid">-->
<!--                    <span-->
<!--                      *ngIf="generalForm.controls.name.errors?.required">Sie müssen dem Projekt einen Namen geben!</span>-->
<!--              </mat-error>-->
<!--            </mat-form-field>-->
          </div>

          <div class="flex flex-row gap-12 justify-between">
            <bic-input [formControlName]="'projectIdIntern'" [label]="'Projekt-ID'" [placeholder]="'Eingeben'" class="w-1/4"></bic-input>
<!--            -->
<!--            <mat-form-field appearance="outline" class="flex w-1/4">-->
<!--              <mat-label>Projekt-ID</mat-label>-->
<!--              <input formControlName="projectIdIntern" matInput>-->
<!--            </mat-form-field>-->

            <bic-select formControlName="projectKind" [label]="'Bauprojektart'" [placeholder]="'Auswählen'" class="w-1/4">
              <ng-container *ngFor="let projectKind of projectKinds | keyvalue">
                <bic-dropdown-element [value]="projectKind.value">{{projectKind.value | projectKind}}</bic-dropdown-element>
              </ng-container>
            </bic-select>

<!--            <mat-form-field appearance="outline" class="flex w-1/4">-->
<!--              <mat-label>Bauprojektart</mat-label>-->
<!--              <mat-select formControlName="projectKind">-->
<!--                <mat-option *ngFor="let projectKind of projectKinds | keyvalue" [value]="projectKind.value">-->
<!--                  {{ projectKind.value | projectKind }}-->
<!--                </mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->


            <bic-select [formControlName]="'isBIM'" [label]="'BIM Projekt ?*'" [placeholder]="'Ja/Nein'" [required]="true" class="w-1/4">
              <bic-dropdown-element [value]="true">Ja</bic-dropdown-element>
              <bic-dropdown-element [value]="false">Nein</bic-dropdown-element>
            </bic-select>



<!--            <mat-form-field appearance="outline" class="flex flex-row h-12 w-1/4" subscriptSizing="dynamic">-->
<!--              <mat-label>BIM Projekt ?</mat-label>-->
<!--              <mat-select formControlName="isBIM" required>-->
<!--                <mat-option [value]="true">-->
<!--                  Ja-->
<!--                </mat-option>-->
<!--                <mat-option [value]="false">-->
<!--                  Nein-->
<!--                </mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->

            <bic-input [formControlName]="'software'" [label]="'Planungssoftware'" [placeholder]="'Eingeben'" class="w-1/4"></bic-input>

<!--            <mat-form-field appearance="outline" class="flex w-1/4">-->
<!--              <mat-label>Planungssoftware</mat-label>-->
<!--              <input formControlName="software" matInput>-->
<!--            </mat-form-field>-->
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #placeData>
    <form [formGroup]="placeForm" class="pt-2">
      <div class="flex flex-col">
        <div>
          <app-address-input
            formControlNameZipcode="zipCode"
            formControlNameCity="buildingPlace"
            formControlNameHouseNumber="houseNumber"
            [stateDisabled]="false"
            [displayPlotNumber]="false"
            [formGroup]="placeForm"></app-address-input>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #timeData>
    <form [formGroup]="timeForm" class="flex flex-row justify-start items-center gap-12 pt-2">

      <bic-input bicDatepickerInput [formControlName]="'beginPlanning'" [label]="'Planungsbeginn'" [placeholder]="'Start'" class="w-1/2" [isDatepicker]="true"
                 (dateChange)="timeForm.get('beginPlanning')?.setValue($event)"></bic-input>
      <bic-input bicDatepickerInput [formControlName]="'endPlanning'" [label]="'Planungsende'" [placeholder]="'Ende'" class="w-1/2" [isDatepicker]="true"
                  (dateChange)="timeForm.get('endPlanning')?.setValue($event)"></bic-input>

      <bic-input bicDatepickerInput [formControlName]="'startExecuting'" [label]="'Ausführungsbeginn'" [placeholder]="'Start'" class="w-1/2" [isDatepicker]="true"
                  (dateChange)="timeForm.get('startExecuting')?.setValue($event)"></bic-input>
      <bic-input bicDatepickerInput [formControlName]="'endExecuting'" [label]="'Ausführungsende'" [placeholder]="'Ende'" class="w-1/2" [isDatepicker]="true"
                  (dateChange)="timeForm.get('endExecuting')?.setValue($event)"></bic-input>

<!--      <mat-form-field appearance="outline" class="flex w-1/2">-->
<!--        <mat-label>Planungszeitraum</mat-label>-->
<!--        <mat-date-range-input [rangePicker]="planningPicker">-->
<!--          <input formControlName="beginPlanning" matStartDate placeholder="Start">-->
<!--          <input formControlName="endPlanning" matEndDate placeholder="Ende">-->
<!--        </mat-date-range-input>-->
<!--        <mat-datepicker-toggle [for]="planningPicker" matSuffix></mat-datepicker-toggle>-->
<!--        <mat-date-range-picker #planningPicker></mat-date-range-picker>-->
<!--        <mat-error *ngIf="timeForm.controls.beginPlanning.hasError('matStartDateInvalid')">Ungültiges-->
<!--          Startdatum-->
<!--        </mat-error>-->
<!--        <mat-error *ngIf="timeForm.controls.endPlanning.hasError('matEndDateInvalid')">Ungültiges Enddatum-->
<!--        </mat-error>-->
<!--      </mat-form-field>-->
<!--      <mat-form-field appearance="outline" class="flex w-1/2">-->
<!--        <mat-label>Ausführungszeitraum</mat-label>-->
<!--        <mat-date-range-input [rangePicker]="executingPicker">-->
<!--          <input formControlName="startExecuting" matStartDate placeholder="Start">-->
<!--          <input formControlName="endExecuting" matEndDate placeholder="Ende">-->
<!--        </mat-date-range-input>-->
<!--        <mat-datepicker-toggle [for]="executingPicker" matSuffix></mat-datepicker-toggle>-->
<!--        <mat-date-range-picker #executingPicker></mat-date-range-picker>-->
<!--        <mat-error *ngIf="timeForm.controls.startExecuting.hasError('matStartDateInvalid')">Ungültiges-->
<!--          Startdatum-->
<!--        </mat-error>-->
<!--        <mat-error *ngIf="timeForm.controls.endExecuting.hasError('matEndDateInvalid')">Ungültiges Enddatum-->
<!--        </mat-error>-->
<!--      </mat-form-field>-->
    </form>
  </ng-template>

</div>
