import {Component, OnInit} from '@angular/core';
import {faBars, faChevronRight, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {faCircleXmark} from "@fortawesome/free-regular-svg-icons";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {ProjectFile, SwmControllerService, SWMRule} from "../../../../api";
import {StateConfigService} from "../../../../services/state/state-config.service";
import {Router} from "@angular/router";
import {faCircleInfo, faSquareDashedCirclePlus} from "@fortawesome/pro-solid-svg-icons";


@Component({
  selector: 'app-fireprotection-check',
  templateUrl: './fireprotection-check.component.html',
  styleUrls: ['./fireprotection-check.component.scss']
})
export class FireprotectionCheckComponent implements OnInit {


  allServices : SWMRule[] = [];
  // allRules: SWMRule[] = [];
  services : SWMRule[] = [];
  selectedServices : SWMRule[] = [];
  selectedFiles: ProjectFile[] = [];
  dropZoneClassName: string = "custom-file-drop-check";
  contentClassName: string = "custom-file-drop-check-content";
  entered: boolean = false;

  constructor(private swmControllerService: SwmControllerService, private stateConfigService: StateConfigService, private router: Router
              ) {
      this.selectedFiles = this.router.getCurrentNavigation()!.extras!.state!.files;
  }

  ngOnInit(): void {
    // this.allServices = ["Löscheinrichtungen", "Brandmelde-, Alarmierungs- und Kommunikationsanlagen", "Not- und Sicherheitsbeleuchtung", "Rettungszeichen", "Bodennahe Leitsysteme"]
    this.swmControllerService.getRules().subscribe(rules => {
      this.allServices = rules;
      this.services = rules;
    });
  }



  public fileOver(event: any) {
    this.dropZoneClassName = "custom-file-drop-check-drag";
    this.contentClassName = "custom-file-drop-check-content-drag";
    this.entered = true;
  }

  public fileLeave(event: any) {
    this.dropZoneClassName = "custom-file-drop-check";
    this.contentClassName = "custom-file-drop-check-content";
    this.entered = false;
  }

  drop(event: CdkDragDrop<String[]>) {
    if (event.previousContainer === event.container) {
      // Wenn das Element in der gleichen Liste verschoben wird, verschieben Sie das Element innerhalb der Liste.
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      // Wenn das Element von einer Liste zur anderen verschoben wird, übertragen Sie das Element zwischen den Listen.
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  addToSelectedServices(service: SWMRule) {
    this.selectedServices.push(service);
    this.allServices = this.allServices.filter(s => s !== service);
  }

  removeFromSelectedServices(service: SWMRule) {
    this.selectedServices = this.selectedServices.filter(s => s !== service);
    // let index = -1;
    // if (this.services[0].includes(service)) {
    //   index = this.services[0].indexOf(service);
    // }else{
    //   index = this.services[1].indexOf(service) + this.services[0].length;
    // }
    // this.allServices.splice(index, 0, service);
    // let temp = ["Löscheinrichtungen", "Brandmelde-, Alarmierungs- und Kommunikationsanlagen", "Not- und Sicherheitsbeleuchtung", "Rettungszeichen", "Bodennahe Leitsysteme"]
    // //filter all services that are in the selectedServices
    // temp.filter(s => !this.selectedServices.includes(s))

    this.allServices.push(service);
  }

  areAllLSelected(): boolean {
    return  this.services.every(s => this.selectedServices.includes(s));
  }

  addAllToSelectedServices() {
    this.selectedServices = this.selectedServices.filter(s => !this.services.includes(s));
    this.selectedServices = this.selectedServices.concat(this.services);
    this.allServices = this.allServices.filter(s => !this.services.includes(s));
  }

  removeAllFromSelectedServices() {
    this.allServices = this.services;
    this.selectedServices = [];
  }

  addOrRemoveFilteredRulesFromSelectedRules() {
    if (this.areAllLSelected()) {
      this.removeAllFromSelectedServices();
    } else {
      this.addAllToSelectedServices();
    }
  }

  startCheck() {
    if(this.selectedFiles.length != 0) {

      let startCheckData = {
        "rules": this.selectedServices,
        "files": this.selectedFiles,
      }
      this.swmControllerService.startCheck(startCheckData , this.stateConfigService.selectedProject.id!).subscribe(checkid => {
        this.router.navigate(['projects/' + this.stateConfigService.selectedProject.id! + '/reports']);
      });
    }
  }

  getCheckStatus() {
    this.swmControllerService.getCheck('3a3c2ec1-6d7e-4c5a-aa6d-964882f6e1d4').subscribe( status => {
    });
  }

    protected readonly faBars = faBars;
    protected readonly faCircleXmark = faCircleXmark;


  protected readonly faPlusCircle = faPlusCircle;
  protected readonly faCircleInfo = faCircleInfo;
  protected readonly faChevronRight = faChevronRight;
  protected readonly faSquareDashedCirclePlus = faSquareDashedCirclePlus;
}
