<div class="flex flex-col w-full h-full justify-between gap-3 pb-3">
  <div class="flex flex-row w-full bg-gray-100 justify-between py-2 px-3">
    <span class="text-gray-700 font-semibold">Benutzerdefinierte Berechtigung {{isExistingRole ? "bearbeiten" : "erstellen"}}</span>
    <fa-icon mat-dialog-close [icon]="faX" class="text-gray-400 h-4 cursor-pointer"></fa-icon>
  </div>

  <div class="flex flex-col gap-3 px-3">
    <form [formGroup]="form">
    <bic-input [formGroup]="form" [formControlName]="'roleTitle'" [label]="'Name der Rolle'" [required]="true"></bic-input>
    </form>
    <hr class="border-gray-100">
    <div *ngFor="let privilege of allPrivileges" (click)="selectPrivilege(privilege); $event.stopPropagation()" class="flex items-center px-3">
      <input type="checkbox" value=""
             class="w-4 h-4 !text-primary bg-gray-50 rounded focus:ring-[none] border border-gray-300"
             [checked]="isSelected(privilege)">
      <label class="text-sm pl-3 font-medium text-gray-900">{{ privilege.name | roleAndPrivileges }}</label>
    </div>
  </div>
  <hr class="border-gray-100">
  <div class="w-full flex flex-row justify-end gap-4 px-3">
    <bic-button mat-dialog-close [text]="'Abbrechen'" [color]="'white'" [textColor]="'black'" [border]="true"></bic-button>
    <bic-button (click)="saveChanges()" [disabled]="!checkInputFilled()" [text]="isExistingRole ? 'Speichern' : 'Erstellen'"></bic-button>
  </div>

</div>
